import React from 'react';
import styles from "./GroupComponent1.module.css";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

const GroupComponent1 = () => {

  const notify = () => {
    toast.success('Coming soon!');
  };

  return (
    <div className={styles.frameParent}>
    <Link target="_blank" to='https://complere-bridge-test-deploy.vercel.app/?destinationChain=complere-chain&sourceChain=base-sepolia' style={{ textDecoration: "none",color:"#FFFF" }}>
      <div  className={styles.bridgeNowParent}>
      <ToastContainer
    position="top-right"
    autoClose={5000}
    hideProgressBar={false}
    newestOnTop={false}
    closeOnClick
    rtl={false}
    pauseOnFocusLoss
    draggable
  />
       
          <div className={styles.bridgeNow}>Bridge Now</div>
        <img
          className={styles.arrowRightCircleSvgrepoComIcon}
          alt=""
          src="/arrowrightcirclesvgrepocom-1.svg"
          />
      </div>
          </Link>
      <i className={styles.complere}>
        <span className={styles.complereTxt}>
          <span>C</span>
          <span className={styles.omplere}>omplere</span>
        </span>
      </i>
      <div className={styles.complereChainsLayer}>
      Creating a chain to move asset tokenization from private blockchains into a secure, seamless, and permissionless Ethereum environment.
      </div>
      <div className={styles.builtOnParent}>
        <i className={styles.builtOn}>Built on</i>
        <img
          className={styles.baseWordmarkBlue1Icon}
          alt=""
          src="/base-wordmark-blue-1.svg"
        />
      </div>
      <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#FFFF" }}>

      <div  className={styles.startBuildingWrapper}>
       
          <div className={styles.bridgeNow}>Start Building</div>
      </div>
      </Link>
    </div>
  );
};

export default GroupComponent1;
