import styles from "./Faq.module.css";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import FrameComponent from "../components/FrameComponent";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const EndOfUser = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [learnButton, setLearnButton] = useState(false);
  const [learnMob, setLearnMob] = useState(false);
  const [isActive, setIsActive] = useState(false);

  const learnDropdown = () => {
    setLearnButton(true);
  };
  const learnDropdown2 = () => {
    setLearnButton(false);
  };
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
    if (learnMob) {
      setLearnMob(false);
    }
  };
  const notify = () => {
    toast.success("Coming soon!");
  };
  const mobLearnMenu = () => {
    setLearnMob(!learnMob);
    setIsOpen(false);
  };
  return (
    <div className={styles.desktop1}>
      <div className={styles.groupParent}>
        <div className={styles.rectangleParent}>
          <div className={styles.groupChild} />
          <div className={styles.groupWrapper}>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
            />
            <Link to="/">
              <img alt="" src="/complere_logo.svg" />
            </Link>
          </div>
          <div onClick={toggleNavbar} className={styles.ham}>
            <img
              className={styles.hamburgerMenuSvgrepoCom1Icon}
              alt=""
              src="/hamburger-menu.png"
            />
          </div>
          {isOpen && (
            <div className={styles.hamMenu}>
              <div onClick={mobLearnMenu} className={styles.menItem}>
                Learn
                <hr className="solid" />
              </div>
              <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#102c57" }}>
              <div className={styles.menItem}>
                Build
                <hr className="solid" />
              </div>
              </Link>
              <div onClick={notify} className={styles.menItem}>
                Explore
                <hr className="solid" />
              </div>
              <div className={styles.menItem}>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "var(--color-white)",
                  }}
                >
                  Ecosystem
                </Link>
                <hr className="solid" />
              </div>
              <Link target="_blank" to='https://complere-bridge-test-deploy.vercel.app/?destinationChain=complere-chain&sourceChain=base-sepolia' style={{ textDecoration: "none",color:"#C71585" }}>
              <div className={styles.menItem}>
                Bridge
              </div>
              </Link>
            </div>
          )}

          {learnMob && (
            <div className={styles.learnMenu}>
              <Link to="/about" onClick={mobLearnMenu}>
                {" "}
                <div style={{ marginTop: "-5px" }} className={styles.learnItem}>
                  About us
                  <hr style={{ marginTop: "10px" }} className="solid" />
                </div>
              </Link>
              <Link to="/blog" style={{ textDecoration: "none" }} >
              <div className={styles.learnItem}>Blog
              <hr style={{ marginTop: "10px" }} className="solid" /></div>
              </Link>
              <Link to="/faq" style={{ textDecoration: "none" }} >
              <div className={styles.learnItem}>FAQs</div>
              </Link>
            </div>
          )}
          <div className={styles.groupDiv}>
            <div className={styles.bridgeParent}>
             
              <Link target="_blank" to='https://complere-bridge-test-deploy.vercel.app/?destinationChain=complere-chain&sourceChain=base-sepolia' style={{ textDecoration: "none",color:"#C71585" }}>
                <div className={styles.bridge}>Bridge</div>
                <img
                  className={styles.arrowUpRightFromSquareSvgIcon}
                  alt=""
                  src="/arrowuprightfromsquaresvgrepocom-1.svg"
                />
                </Link>
            </div>

            <div
              onMouseEnter={learnDropdown}
              onMouseLeave={learnDropdown2}
              className={styles.dropdownArrowSvgrepoCom1Parent}
            >
            
              <img
                className={styles.dropdownArrowSvgrepoCom1Icon}
                alt=""
                src="/dropdownarrowsvgrepocom-1.svg"
              />
              <div className={styles.bridge}>Learn</div>
              {learnButton && (
                <div className={styles.learnMenu}>
                  <Link to="/about" style={{ textDecoration: "none" }}>
                    <div className={styles.learnItem}>
                      About us
                      <hr className="solid" />
                    </div>
                  </Link>
                  <Link to="/blog" style={{ textDecoration: "none" }} >
                  <div className={styles.learnItem2}>Blog
                  <hr className="solid" /></div>
                  </Link>
                  <Link to="/faq" style={{ textDecoration: "none" }} >
                  <div className={styles.learnItem2}>FAQs</div>
                  </Link>
                </div>
              )}
            </div>

            <div className={styles.dropdownArrowSvgrepoCom2Parent}>
             
                <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#102c57" }}>
                <img
                  className={styles.dropdownArrowSvgrepoCom2Icon}
                  alt=""
                  src="/dropdownarrowsvgrepocom-1.svg"
                />
                <div className={styles.bridge}>Build</div>
                </Link>
            </div>
            <Tooltip
              style={{ marginLeft: 100 }}
              interactive
              trigger="click"
              touchHold={true}
              stickyDuration={200}
              html={
                <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
                  <strong style={{ fontFamily: "Inter" }}>
                    Coming soon...
                  </strong>
                </div>
              }
            >
              <div className={styles.dropdownArrowSvgrepoCom3Parent}>
                <img
                  className={styles.dropdownArrowSvgrepoCom3Icon}
                  alt=""
                  src="/dropdownarrowsvgrepocom-1.svg"
                />
                <div className={styles.bridge}>Explore</div>
              </div>
            </Tooltip>
            <div className={styles.dropdownArrowSvgrepoCom3Parent4}>
              <div className={styles.bridge}>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "var(--color-darkslateblue)",
                  }}
                >
                  Ecosystem
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div>

        <div className={styles.header}>
        <h1>Frequently Asked Questions</h1>
      </div>

     
      <div className={styles.faq}>
        <div className={styles.faqContent}>

            <div className={styles.accordion}>

                {accordionData.map(({ title, content }) => (
                    <Accordion title={title} content={content} />
                ))}
            </div>

            <div className={styles.accordion2}>

                {accordionData2.map(({ title, content }) => (
                    <Accordion2 title={title} content={content} />
                ))}
            </div>
     
        </div>

        <div className={styles.contactBox}>
            <div className={styles.boxa}>
                <h1 style={{color:'var(--color-darkslateblue)'}}>Need more help ?</h1>
                <p style={{fontFamily:'inter'}}>We'll be happy to help you. Get in touch with our team.</p>
                <div  onClick={() => window.location = 'mailto:hello@complere.xyz'} className={styles.conbox}>
                    Contact Us 
                </div>
            </div>

            <div>
            <img
                className={styles.conIcon}
                alt=""
                src="/contact-img.svg"
                />
            </div>
        </div>

      </div>


      <div className={styles.footer}>
        <FrameComponent />
      </div>
    </div>
  );
};
export default EndOfUser;

const Accordion = ({ title, content }) => {
    const [isActive, setIsActive] = useState(false);
    return (
      <div  className={styles.accordionTitle} onClick={() => setIsActive(!isActive)}>
                    <div className={styles.accordionHeader}>
                        <div>{title}</div>
                        <div>{isActive ? '-' : '+'}</div>
                    </div>
                    {isActive && <div className={styles.accordionContent}>{content}</div>}
                </div>
    );
  };

  const Accordion2 = ({ title, content }) => {
    const [isActive, setIsActive] = useState(false);
    return (
      <div  className={styles.accordionTitle2} onClick={() => setIsActive(!isActive)}>
                    <div className={styles.accordionHeader2}>
                        <div>{title}</div>
                        <div>{isActive ? '-' : '+'}</div>
                    </div>
                    {isActive && <div className={styles.accordionContent2}>{content}</div>}
                </div>
    );
  };

  const accordionData = [
    {
      title: 'What is Complere Chain and what does it do ?',
      content: `Complere Chain is a Layer 3 blockchain platform designed to facilitate cross-chain interoperability. It enables assets tokenized on its platform to seamlessly interact with other public blockchains, DeFi protocols, and liquidity pools, ensuring efficient asset utilization and composable liquidity.`
    },
    {
      title: 'How does Complere ensure security and compliance ?',
      content: `Complere combines Ethereum's robust security with customized features to meet Real World Assets (RWA) requirements. It provides a transparent, regulation-compliant environment using Account Abstraction and Zero-Knowledge Proofs, ensuring both privacy and compliance.`
    },
    {
      title: 'What makes Complere different from other blockchains ?',
      content: `Unlike permissioned chains that may compromise on security and decentralization, Complere offers a secure, permissionless environment on Ethereum. It also provides flexible self-custodial and custodial solutions, making it accessible to a broad range of users.`
    },
    {
      title: 'How does Complere facilitate cross-chain interoperability ?',
      content: `Complere enables tokenized assets on its platform to interact with other public blockchains, DeFi protocols, and liquidity pools. This interoperability ensures composable liquidity, allowing for efficient asset management and broader market access.`
    },
    {
      title: 'What custody options does Complere offer for asset control ?',
      content: `Complere offers a range of custody options to cater to different user preferences. Whether users prefer self-custodial solutions or trust custodial services, Complere provides the necessary flexibility.`
    },
    {
      title: 'How does Complere facilitate interoperability between different blockchains ?',
      content: `Complere is a Layer 3 chain built on the BASE network, enabling seamless communication and value transfer between different blockchains and distributed ledger technologies. This interoperability is essential for Real World Assets (RWAs), allowing tokenized assets to move smoothly across multiple platforms and ecosystems.`
    },
    {
      title: 'Why is regulatory compliance important for Complere ?',
      content: `Regulatory compliance is important for Complere as it ensures that all transactions adhere to legal standards, reducing legal risks and making the platform trustworthy for institutional investors and users.`
    },
    {
      title: 'What are the benefits of using Zero Knowledge Proof technology in Complere ?',
      content: `Zero Knowledge Proof technology ensures the security and privacy of transactions, protecting sensitive information without compromising on transparency. This is especially critical for institutional settings where data protection is paramount.`
    }

  ];

  const accordionData2 = [
    {
      title: 'What benefits does Complere offer for startups in the RWA tokenization space ?',
      content: `Complere provides a robust and versatile platform for startups to build and scale their offerings. It enhances blockchain capabilities with tailored functionalities, ensures a secure and transparent environment, and facilitates gasless transactions, reducing barriers to entry and improving user experience.`
    },
    {
      title: 'How does Complere support institutional adoption of tokenization and cryptocurrency ?',
      content: `By enabling interoperability with traditional financial systems and prioritizing compliance, Complere acts as a gateway for institutional adoption of tokenization and cryptocurrency through RWAs. This opens new avenues for startups to attract institutional investors and fosters a more inclusive financial ecosystem.`
    },
    {
      title: "What are the key features of Complere's platform ?",
      content: `Key features include cross-chain interoperability, composable liquidity, a secure and regulation-compliant environment, self-custodial and custodial solutions, gasless transactions, and tailored functionalities to meet specific user and use case needs.`
    },
    {
      title: 'What is the primary focus of Complere in terms of regulatory standards ?',
      content: `Complere prioritizes adherence to legal standards, ensuring that all transactions and interactions meet necessary legal requirements. This compliance is crucial for institutional investors and users navigating financial regulations, mitigating legal risks.`
    },
    {
      title: 'How does Complere optimize transaction efficiency ?',
      content: `Complere leverages Ethereum Layer 2 solutions BASE to optimize efficiency. This approach reduces gas fees typically associated with Layer 1 blockchains, making transactions faster and more cost-effective.`
    },
    {
      title: 'What technology does Complere use to ensure the security and privacy of transactions ?',
      content: `Complere utilizes Zero Knowledge Proof technology to protect sensitive information related to Real World Assets (RWAs). This ensures that transactions remain secure and private without compromising transparency.`
    },
    {
      title: 'How does Complere make blockchain interactions easier for mainstream users ?',
      content: `Complere simplifies blockchain usage through account abstraction features. This innovation abstracts the complexities of blockchain accounts, facilitating easier adoption and interaction for mainstream users.`
    }
  ];