import TetherUSDTIcon from "./TetherUSDTIcon";
import USDCoinUSDC from "./USDCoinUSDC";
import CardanoADAIcon from "./CardanoADAIcon";
import XRPXRPIcon from "./XRPXRPIcon";
import LitecoinLTCIcon from "./LitecoinLTCIcon";
import ChainlinkLINKIcon from "./ChainlinkLINKIcon";
import styles from "./FrameComponent1.module.css";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Link } from "react-router-dom";

const FrameComponent1 = () => {
  
  const notify = () => {
    toast.success('Coming soon!');
  };

  return (
    <div className={styles.ellipseParent}>
      <div className={styles.frameChild} />
      <TetherUSDTIcon />
      <USDCoinUSDC />
      <CardanoADAIcon />
      <XRPXRPIcon />
      <LitecoinLTCIcon />
      <ChainlinkLINKIcon />
      <div className={styles.frameItem} />
      <img className={styles.frameInner} alt="" src="/group-45@2x.png" />
      <div className={styles.groupWrapper}>
        <div className={styles.frameParent}>           
             <Link target="_blank" to='https://complere-bridge-test-deploy.vercel.app/?destinationChain=complere-chain&sourceChain=base-sepolia' style={{ textDecoration: "none",color:"#FFFF" }}>
            <div  className={styles.bridgeNowParent}>
              <div className={styles.bridgeNow}>
                Bridge Now
                </div>
            <img
              className={styles.arrowRightCircleSvgrepoComIcon}
              alt=""
              src="/arrowrightcirclesvgrepocom-1.svg"
              />
          </div>
              </Link>
          <div className={styles.useANative}>
          Use complere ecosystem to move Tokenized assets on-chain in a secure and seamless enovironment
          </div>
          <div className={styles.startUsingComplereContainer}>
            <span className={styles.startUsingComplereContainer1}>
              <p className={styles.startUsingComplere}>
                <span className={styles.startUsing}>{`Start using `}</span>
                <span>
                  <i className={styles.c}>C</i>
                </span>
                <span className={styles.omplere}>
                  <span>omplere</span>
                </span>
              </p>
              <p className={styles.byBridgingToTheNetwork}>
                <span className={styles.omplere}>
                  <span>by bridging to the network</span>
                </span>
              </p>
            </span>
          </div>
        </div>
      </div>
    </div>
  );
};

export default FrameComponent1;
