import styles from "./SectionForm.module.css";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import { useState } from "react";
import { slide as Menu } from "react-burger-menu";
import { ToastContainer, toast } from "react-toastify";
import { Link } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";

const SectionForm = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [learnButton, setLearnButton] = useState(false);
  const [learnMob, setLearnMob] = useState(false);

  const learnDropdown = () => {
    setLearnButton(true);
  };
  const learnDropdown2 = () => {
    setLearnButton(false);
  };
  const mobLearnMenu = () => {
    setLearnMob(!learnMob);
    setIsOpen(false);
  };
  const notify = () => {
    toast.success("Coming soon!");
  };

  const toggleNavbar = () => {
    setIsOpen(!isOpen);
    if (learnMob) {
      setLearnMob(false);
    }
  };
  // console.log(learnButton,'learnButton','lmon',learnMob)
  return (
    <div className={styles.rectangleParent}>
      <div className={styles.groupChild} />
      <div className={styles.groupWrapper}>
        {/* <div className={styles.groupParent}>
          <div className={styles.groupContainer}>
            <div className={styles.groupParent}>
              <img className={styles.groupIcon} alt="" src="/rectangle-img.png" />
              <img className={styles.groupIcon1} alt="" src="/rectangle-img.png" />
            </div>
            <div className={styles.c}>C</div>
          </div>
          <div className={styles.complere}>Complere</div>
        </div> */}
       <Link to="/"> <img alt="" src="/complere_logo.svg" /></Link>
      </div>
      <div onClick={toggleNavbar} className={styles.ham}>
        <img
          className={styles.hamburgerMenuSvgrepoCom1Icon}
          alt=""
          src="/hamburger-menu.png"
        />
      </div>
      {isOpen && (
        <div className={styles.hamMenu}>
          <div onClick={mobLearnMenu} className={styles.menItem}>
            Learn
            <hr className="solid" />
          </div>
          <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#102c57" }}>
          <div className={styles.menItem}>
            Build
            <hr className="solid" />
          </div>
          </Link>
          <div onClick={notify} className={styles.menItem}>
            Explore
            <hr className="solid" />
          </div>
          <div className={styles.menItem}>
          <ScrollLink
              to="hero"
              spy={true}
              smooth={true}
              offset={40}
              duration={500}
              onClick={toggleNavbar}
            >Ecosystem
            </ScrollLink>
            <hr className="solid" />
          </div>
          <Link target="_blank" to='https://complere-bridge-test-deploy.vercel.app/?destinationChain=complere-chain&sourceChain=base-sepolia' style={{ textDecoration: "none",color:"#C71585" }}>
          <div  className={styles.menItem}>
            Bridge
            {/* <hr className="solid" /> */}
          </div>
          </Link>
        </div>
      )}
      {learnMob && (
        <div className={styles.learnMenu}>
          <Link to="/about"  onClick={mobLearnMenu}>
            {" "}
            <div style={{ marginTop: "-5px" }} className={styles.learnItem}>
              About us
              <hr style={{ marginTop: "20px" }} className="solid" />
            </div>
          </Link>
          <Link to="/blogpage" style={{ textDecoration: "none" }}  onClick={mobLearnMenu}>
          <div className={styles.learnItem}>Blog</div>
          </Link>
        </div>
      )}

      <div className={styles.groupDiv}>
        <div className={styles.bridgeParent}>
          <Link target="_blank" to='https://complere-bridge-test-deploy.vercel.app/?destinationChain=complere-chain&sourceChain=base-sepolia' style={{ textDecoration: "none",color:"#C71585" }}>
            <div className={styles.bridge}>Bridge</div>
            <img
              className={styles.arrowUpRightFromSquareSvgIcon}
              alt=""
              src="/arrowuprightfromsquaresvgrepocom-1.svg"
              />
          </Link>
        </div>
        <div className={styles.dropdownEco}>
          <div className={styles.bridge}>
            <ScrollLink
              to="hero"
              spy={true}
              smooth={true}
              offset={60}
              duration={500}
            >
              Ecosystem
            </ScrollLink>
          </div>
        </div>
        <div
          onMouseEnter={learnDropdown}
          onMouseLeave={learnDropdown2}
          className={styles.dropdownArrowSvgrepoCom1Parent}
        >
          <img
            className={styles.dropdownArrowSvgrepoCom1Icon}
            alt=""
            src="/dropdownarrowsvgrepocom-1.svg"
          />
          <div className={styles.bridge}>Learn</div>
          {learnButton && (
            <div className={styles.learnMenu}>
              <Link
                to="/about"
                style={{ textDecoration: "none" }}
              >
                <div className={styles.learnItem}>
                  About us
                  <hr className="solid" />
                </div>
              </Link>
              <Link to="/blogpage" style={{ textDecoration: "none" }} >
              <div className={styles.learnItem2}>Blog
              <hr className="solid" />
              </div>
              </Link>
              <Link to="/faq" style={{ textDecoration: "none" }} >
              <div className={styles.learnItem2}>FAQs</div>
              </Link>
            </div>
          )}
        </div>

        <div className={styles.dropdownArrowSvgrepoCom2Parent}>
            <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#102c57" }}>
            <img
              className={styles.dropdownArrowSvgrepoCom2Icon}
              alt=""
              src="/dropdownarrowsvgrepocom-1.svg"
            />
            <div className={styles.bridge}>Build</div>
            </Link>
        </div>
        <Tooltip
          style={{ marginLeft: 100 }}
          interactive
          trigger="click"
          touchHold={true}
          stickyDuration={200}
          html={
            <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
              <strong style={{ fontFamily: "Inter" }}>Coming soon...</strong>
            </div>
          }
        >
          <div className={styles.dropdownArrowSvgrepoCom3Parent}>
            <img
              className={styles.dropdownArrowSvgrepoCom3Icon}
              alt=""
              src="/dropdownarrowsvgrepocom-1.svg"
            />
            <div className={styles.bridge}>Explore</div>
          </div>
        </Tooltip>
      </div>
      <img className={styles.groupItem} alt="" src="/black-line.svg" />
      <img className={styles.groupInner} alt="" src="/vector-2.svg" />
      <img className={styles.vectorIcon} alt="" src="/vector-3.svg" />
      <img className={styles.groupChild1} alt="" src="/vector-4.svg" />
    </div>
  );
};

export default SectionForm;
