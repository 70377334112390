// import styles from "./Desktop.module.css";
import styles from "./PrivacyPolicy.module.css";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import FrameComponent from "../components/FrameComponent";
import SectionForm from "../components/SectionForm";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const PrivacyPolicy = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [learnButton, setLearnButton] = useState(false);
  const [learnMob, setLearnMob] = useState(false);

  const learnDropdown = () => {
    console.log('true')
    setLearnButton(true);
  };
  const learnDropdown2 = () => {
    setLearnButton(false);
    console.log('false')
  };
  console.log(learnButton,'learnButton')
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
    if (learnMob) {
      setLearnMob(false);
    }
  };
  const notify = () => {
    toast.success("Coming soon!");
  };
  const mobLearnMenu = () => {
    setLearnMob(!learnMob);
    setIsOpen(false);
  };
  return (
    <div className={styles.desktop1}>
     
      <div className={styles.nav}>
      {/* <SectionForm /> */}
      <div className={styles.rectangleParent}>
      <div className={styles.groupChild} />
      <div className={styles.groupWrapper}>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      <Link to="/"><img alt="" src="/complere_logo.svg" /></Link>
      </div>
      <div onClick={toggleNavbar} className={styles.ham}>
            <img
              className={styles.hamburgerMenuSvgrepoCom1Icon}
              alt=""
              src="/hamburger-menu.png"
            />
          </div>
          {isOpen && (
        <div className={styles.hamMenu}>
          <div onClick={mobLearnMenu} className={styles.menItem}>
            Learn
            <hr className="solid" />
          </div>
          <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#102c57" }}>
          <div className={styles.menItem}>
            Build
            <hr className="solid" />
          </div>
          </Link>
          <div onClick={notify} className={styles.menItem}>
            Explore
            <hr className="solid" />
          </div>
          <div className={styles.menItem}>
          <Link to="/"
                style={{ textDecoration: "none",color:'var(--color-white)' }}>
                  Ecosystem
            </Link>
            <hr className="solid" />
          </div>
          <Link target="_blank" to='https://complere-bridge-test-deploy.vercel.app/?destinationChain=complere-chain&sourceChain=base-sepolia' style={{ textDecoration: "none",color:"#C71585" }}>
          <div  className={styles.menItem}>
            Bridge
            {/* <hr className="solid" /> */}
          </div>
          </Link>
        </div>
            )}

        {learnMob && (
        <div className={styles.learnMenu}>
          <Link to="/about"  onClick={mobLearnMenu}>
            {" "}
            <div style={{ marginTop: "-5px" }} className={styles.learnItem}>
              About us
              <hr style={{ marginTop: "20px" }} className="solid" />
            </div>
          </Link>
          <Link to="/blog" style={{ textDecoration: "none" }} >
          <div className={styles.learnItem}>Blog
          <hr style={{ marginTop: "20px" }} className="solid" />
          </div>
          </Link>
          <Link to="/faq" style={{ textDecoration: "none" }} >
                  <div style={{ marginTop: "5px" }} className={styles.learnItem2}>FAQs</div>
          </Link>
        </div>
      )}
      <div className={styles.groupDiv}>
        <div className={styles.bridgeParent}>
       
        <Link target="_blank" to='https://complere-bridge-test-deploy.vercel.app/?destinationChain=complere-chain&sourceChain=base-sepolia' style={{ textDecoration: "none",color:"#C71585" }}>
          <div className={styles.bridge}>Bridge</div>
          <img
            className={styles.arrowUpRightFromSquareSvgIcon}
            alt=""
            src="/arrowuprightfromsquaresvgrepocom-1.svg"
          />
        </Link>
        </div>
        
        <div
        onMouseEnter={learnDropdown}
        onMouseLeave={learnDropdown2}
         className={styles.dropdownArrowSvgrepoCom1Parent}>
        {/* <Tooltip style={{marginLeft:-20}}
              interactive
              trigger="click"
              touchHold={true}
              stickyDuration={200}
              html={
                
                  <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
                    <strong style={{ fontFamily: "Inter" }}>
                      Coming soon...
                    </strong>
                  </div>
              }
            > */}
          <img
            className={styles.dropdownArrowSvgrepoCom1Icon}
            alt=""
            src="/dropdownarrowsvgrepocom-1.svg"
          />
          <div className={styles.bridge}>Learn</div>
          {learnButton && (
            <div className={styles.learnMenu}>
              <Link
                to="/about"
                style={{ textDecoration: "none" }}
              >
                <div className={styles.learnItem}>
                  About us
                  <hr className="solid" />
                </div>
              </Link>
              <Link to="/blog" style={{ textDecoration: "none" }} >
              <div className={styles.learnItem2}>Blog
              <hr className="solid" /></div>
              </Link>
              <Link to="/faq" style={{ textDecoration: "none" }} >
                  <div className={styles.learnItem2}>FAQs</div>
              </Link>
            </div>
          )}
        {/* </Tooltip> */}
        </div>
        
        <div className={styles.dropdownArrowSvgrepoCom2Parent}>
       
        <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#102c57" }}>
          <img
            className={styles.dropdownArrowSvgrepoCom2Icon}
            alt=""
            src="/dropdownarrowsvgrepocom-1.svg"
          />
          <div className={styles.bridge}>Build</div>
        </Link>
        </div>
        <Tooltip style={{marginLeft:100}}
              interactive
              trigger="click"
              touchHold={true}
              stickyDuration={200}
              html={
                
                  <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
                    <strong style={{ fontFamily: "Inter" }}>
                      Coming soon...
                    </strong>
                  </div>
              }
            >
        <div className={styles.dropdownArrowSvgrepoCom3Parent}>
          <img
            className={styles.dropdownArrowSvgrepoCom3Icon}
            alt=""
            src="/dropdownarrowsvgrepocom-1.svg"
          />
          <div className={styles.bridge}>Explore</div>
        </div></Tooltip>
        <div className={styles.dropdownArrowSvgrepoCom3Parent4}>
                <div className={styles.bridge}>
                  <Link to="/"
                style={{ textDecoration: "none",color:'var(--color-darkslateblue)' }}>Ecosystem</Link></div>
              </div>
      </div>
    </div>
      </div>
      <div className={styles.policyText}>
        <p>Dear User,</p>
        <p>
          Thank you for choosing to be part of our community at Complere,
          doing business as Complere (“Complere”, “we”, “us”, or “our”). We are committed
          to protecting your personal information and your right to privacy. If
          you have any questions or concerns about our policy or our practices
          regarding your personal information, please contact us at
          hello@complere.xyz. You trust us with your personal information when
          you visit our mobile application and use our services. We take your
          privacy very seriously. In this privacy policy, we seek to explain to
          you in the clearest way possible what information we collect, how we
          use it, and what rights you have about it. Please take some time to
          read through it carefully, as it is important. If there are any terms
          in this privacy policy that you disagree with, please discontinue the
          use of our Apps and our services. This privacy policy applies to all
          information collected through our mobile application ("Complere") and/or
          any related services or events (we refer to them collectively in this
          privacy policy as the "Services"). Please read this privacy policy
          carefully, as it will help you make informed decisions about sharing
          your personal information with us.
        </p>
      </div>

      <div className={styles.policyText2}>
        <h4>1. WHAT INFORMATION DO WE COLLECT?</h4>
        <p>
          In Short: We collect personal information that you provide to us. We
          collect the personal information you voluntarily provide us when
          registering at the Apps, expressing an interest in obtaining
          information about us or our products and services, participating in
          activities on the Apps, or otherwise contacting us. Social Media Login
          Data; We may allow you to register using social media account details,
          like your Facebook. If you choose to register this way, we will
          collect the Information described in the section "HOW DO WE HANDLE
          YOUR SOCIAL LOGINS" below. All personal information you provide must
          be true, complete, and accurate, and you must notify us of any changes
          to such personal information. Information automatically collected In
          Short: Some information — such as IP address and/or browser and device
          characteristics — is collected automatically when you visit our Complere
          App. We automatically collect certain information when you visit, use,
          or navigate the Apps. This information does not reveal your specific
          identity (like your name or contact information) but may include
          device and usage information, such as your IP address, browser and
          device characteristics, operating system, language preferences,
          referring URLs, device name, country, location, information about how
          and when you use our Apps and other technical information. This
          information is primarily needed to maintain the security and operation
          of our Apps and for our internal analytics and reporting purposes.
          Like many businesses, we also collect information through cookies and
          similar technologies.
        </p>
        <h4>2. HOW DO WE USE YOUR INFORMATION?</h4>
        <p>
          We use personal information to provide you with services & products
          you explicitly requested, to resolve disputes, troubleshoot concerns,
          help promote safe services and updates, customize your experience,
          detect & protect us against error, fraud, and other criminal activity,
          enforce our terms and conditions, etc. We use the information we
          collect or receive: To facilitate account creation and the logon
          process. If you link your account with us to a third-party account
          (such as your Facebook account), we use the information you allow us
          to collect from those third parties to facilitate the account creation
          and the logon process for the performance of the contract. See the
          section below, "HOW DO WE HANDLE YOUR SOCIAL LOGINS," for further
          information. To send administrative information to you. We may use
          your personal information to send you product, service, and new
          feature information and/or information about changes to our terms,
          conditions, and policies.
        </p>
        <h4>3. WILL YOUR INFORMATION BE SHARED WITH ANYONE?</h4>
        <p>
          We only share information with your consent to comply with laws, to
          provide you with services, to protect your rights, or to fulfill
          business obligations. We will not sell, share or rent your personal
          information to any 3rd party or use your email address/mobile number
          for unsolicited emails and/or SMS. Any emails and/or SMS sent by Complere
          will only be in connection with the provision of agreed services &
          products and this Privacy Policy. We may process or share data on the
          following legal basis: Consent: We may process your data if you have
          given us specific consent to use your personal information for a
          specific purpose. Legal Obligations: We may disclose your information
          where we are legally required to do so to comply with applicable law,
          governmental requests, a judicial proceeding, court order, or legal
          processes, such as in response to a court order or a subpoena
          (including in response to public authorities to meet national security
          or law enforcement requirements). Vital Interests: We may disclose
          your information where we believe it is necessary to investigate,
          prevent, or take action regarding potential violations of our
          policies, suspected fraud, situations involving potential threats to
          the safety of any person and illegal activities, or as evidence in
          litigation in which we are involved.
        </p>
        <h4>4. DO WE USE COOKIES AND OTHER TRACKING TECHNOLOGIES?</h4>
        <p>
          We may use cookies and other tracking technologies to collect and
          store your information automatically. A "cookie" is a small piece of
          information stored by a web server on a web browser so it can be later
          read back from that browser. Complere uses cookie and tracking technology
          depending on the features offered. No personal information will be
          collected automatically via cookies and other tracking technology;
          however, if you previously provided personally identifiable
          information, cookies may be tied to such information.
        </p>
        <h4>5. HOW DO WE HANDLE YOUR SOCIAL LOGINS?</h4>
        <p>
          If you choose to register or log in to our services using a social
          media account, we may have access to certain information about you.
          Our App allows you to register and log in using your third-party
          social media account details (like your Facebook logins). Where you
          choose to do this, we will receive certain profile information about
          you from your social media provider. The profile Information we
          receive may vary depending on the social media provider concerned but
          will often include your name, e-mail address, friends list, profile
          picture, and other information you choose to make public. We will use
          the information we receive only for the purposes described in this
          privacy policy or otherwise made clear to you on the Apps. Please note
          that we do not control, and are not responsible for, other uses of
          your personal information by your third-party social media provider.
          We recommend that you review their privacy policy to understand how
          they collect, use, and share your personal information and how you can
          set your privacy preferences on their sites and apps.
        </p>
        <h4>6. DO WE MAKE UPDATES TO THIS POLICY?</h4>
        <p>
          Yes, we will update this policy to comply with relevant laws or
          changes in our policies from time to time. We may update this privacy
          policy from time to time. The updated version will be indicated by an
          updated “Revised” date, and the updated version will be effective as
          soon as it is accessible. If we make material changes to this privacy
          policy, we may notify you by prominently posting a notice of such
          changes or by directly sending you a notification. We encourage you to
          review this privacy policy frequently to be informed of how we are
          protecting your information.
        </p>
        <h4>OUR DATA INTEGRITY POLICY</h4>
        <p>
          Our all-encompassing Data Integrity Policy covers the following
          avenues:
        </p>
        <p>
          1.Data management has become a very important component with the
          volume growing exponentially.
        </p>
        <p>
          2. Data reliability, consistency as well as quality management will be
          taken up across the following processes that are a part of the data
          lifecycle:
        </p>
        <p>
          a) Collection of raw data (via web and app user interfaces; and APIs
          from third-party vendors)
          <br />
          b) Processing of the raw data into structured format (using MongoDB
          and JSON objects retrieved from the API callbacks)
          <br />
          c) Computation of the data (using backend business logic)
          <br />
          d) Reporting of the data (via frontend admin panels, exports, database
          storage)
          <br />
          e) Retention of the data (via database storage on the cloud, as well
          as updating of an immutable ledger in our underlying blockchain
          system)
          <br />
          f) Archival and retrieval of the data (via database storage on the
          cloud and a blockchain explorer that we have built)
          <br />
          g) Authorized destruction of the data (via Update operations on our
          database and appending new transactions to the blockchain system
          showing addition/updation to the historical data)
        </p>
        <p>
          3.Complere’s data management will follow the standard of being ‘Complete,
          Consistent, Enduring and Available.’
        </p>
        <p>
          4. Complere and its associated systems will be collecting data on the
          following fronts:
          <br />
          a) Personal information to build the user’s profile.
          <br />
          b)Deposit and Loan information is based on the data entered in our
          app.
          <br />
          c) Deposit and Loan information is based on the data shared with us
          through our APIs by a third party.
          <br />
          d) Clickstream data is aggregated and processed by reliable analytics
          platforms such as Google Analytics and Firebase.
        </p>
        <p>
          5.Complere  will be following the below-mentioned data privacy policies:
          <br />
          a) Personally Identifiable Information (PII) won’t be shared with
          Google via the Analytics codebase.
          <br />
          b)The semi-public Blockchain Explorer will not display PIIs as a part
          of the transaction data shown on the explorer views.
        </p>
        <p>
          6.We understand that mismanagement of the data, or inclusion of
          suspicious data in our systems, will majorly bring a bad name to the
          reputation of the company, as well as hinder our efforts of helping
          farmers and other customers by making their lives significantly easier
          as we enable them to avail working capital and personal finance
          against their produce deposited at a nearby warehouse, with an
          all-encircling digital process.
        </p>
        <h4>HOW CAN YOU CONTACT US ABOUT THESE POLICIES?</h4>
        <p>
          If you have questions or comments about this policy, you may email us
          at hello@complere.xyz.
        </p>
      </div>
      <div className={styles.footer}>
      <FrameComponent />
      </div>
    </div>
  );
};
export default PrivacyPolicy;
