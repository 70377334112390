import SectionForm from "../components/SectionForm";
import GroupComponent1 from "../components/GroupComponent1";
import ContainerWrapper from "../components/ContainerWrapper";
import FrameComponent2 from "../components/FrameComponent2";
import GroupComponent from "../components/GroupComponent";
import Container from "../components/Container";
import FrameComponent1 from "../components/FrameComponent1";
import FrameComponent from "../components/FrameComponent";
import PartnersComponent from "../components/PartnersComponent";
import styles from "./Desktop.module.css";

const Desktop = () => {
  return (
    <div className={styles.desktop1}>
      <div className={styles.groupParent}>
        <SectionForm />
        <img className={styles.frameChild} alt="" src="/group-62@2x.png" />
        <i className={styles.insti}>Institutions</i>
        <i className={styles.web2}>Web2 Startups</i>
        <i className={styles.web3}>Web3 Startups</i>
        <GroupComponent1 />
      </div>
      <PartnersComponent />
      <ContainerWrapper />
      <FrameComponent2 />
      <div className={styles.topographic1Parent}>
        <img
          className={styles.topographic1Icon}
          alt=""
          src="/topographic-1@2x.png"
        />
        <div className={styles.groupContainer}>
          <GroupComponent />
          <Container />
          <img className={styles.groupChild} alt="" src="/line.png" />
        </div>
      </div>
      <FrameComponent1 />
      <FrameComponent />
    </div>
  );
};

export default Desktop;
