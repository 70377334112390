import styles from "./ChainlinkLINKIcon.module.css";

const ChainlinkLINKIcon = () => {
  return (
    <img
      className={styles.chainlinkLinkIcon}
      alt=""
      src="/chainlink-link@2x.png"
    />
  );
};

export default ChainlinkLINKIcon;
