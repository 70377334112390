import styles from "./About.module.css";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import FrameComponent from "../components/FrameComponent";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { ToastContainer, toast } from "react-toastify";
import Linkedin from "../components/Linkedin";
import Instagram from "../components/Instagram";
const About = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [learnButton, setLearnButton] = useState(false);
  const [learnMob, setLearnMob] = useState(false);

  const location = useLocation();
  const { hash, pathname, search } = location;
  console.log(pathname, "lsoslso");

  const learnDropdown = () => {
    setLearnButton(true);
  };
  const learnDropdown2 = () => {
    setLearnButton(false);
  };
  const toggleNavbar = () => {
    console.log("tooclcl");
    setIsOpen(!isOpen);
    if (learnMob) {
      setLearnMob(false);
    }
  };
  const notify = () => {
    toast.success("Coming soon!");
  };
  const mobLearnMenu = () => {
    setLearnMob(!learnMob);
    setIsOpen(false);
  };
  console.log(learnButton, "learnButton", isOpen, "lmon", learnMob);

  return (
    <div className={styles.desktop1}>
      {/* <div className={styles.groupParent}>
        <div className={styles.rectangleParent}>
          <div className={styles.groupChild} />
          <div className={styles.groupWrapper}>
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
            />
            <Link to="/">
              <img alt="" src="/complere_logo.svg" />
            </Link>
          </div>
          <div onClick={toggleNavbar} className={styles.ham}>
            <img
              className={styles.hamburgerMenuSvgrepoCom1Icon}
              alt=""
              src="/hamburger-menu.png"
            />
          </div>
          {isOpen && (
            <div className={styles.hamMenu}>
              <div onClick={mobLearnMenu} className={styles.menItem}>
                Learn
                <hr className="solid" />
              </div>
              <div onClick={notify} className={styles.menItem}>
                Build
                <hr className="solid" />
              </div>
              <div onClick={notify} className={styles.menItem}>
                Explore
                <hr className="solid" />
              </div>
              <div className={styles.menItem}>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "var(--color-white)",
                  }}
                >
                  Ecosystem
                </Link>
                <hr className="solid" />
              </div>
              <div onClick={notify} className={styles.menItem}>
                Bridge
              </div>
            </div>
          )}

          {learnMob && (
            <div className={styles.learnMenu}>
              <Link to="/about" onClick={mobLearnMenu}>
                {" "}
                <div style={{ marginTop: "-5px" }} className={styles.learnItem}>
                  About us
                  <hr style={{ marginTop: "20px" }} className="solid" />
                </div>
              </Link>
              <Link to="/blog" onClick={mobLearnMenu}>
                <div className={styles.learnItem}>Blog</div>
              </Link>
            </div>
          )}
          <div className={styles.groupDiv}>
            <div className={styles.bridgeParent}>
              <Tooltip
                style={{ marginLeft: 5 }}
                interactive
                trigger="click"
                touchHold={true}
                stickyDuration={200}
                html={
                  <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
                    <strong style={{ fontFamily: "Inter" }}>
                      Coming soon...
                    </strong>
                  </div>
                }
              >
                <div className={styles.bridge}>Bridge</div>
                <img
                  className={styles.arrowUpRightFromSquareSvgIcon}
                  alt=""
                  src="/arrowuprightfromsquaresvgrepocom-1.svg"
                />
              </Tooltip>
            </div>

            <div
              onMouseEnter={learnDropdown}
              onMouseLeave={learnDropdown2}
              className={styles.dropdownArrowSvgrepoCom1Parent}
            >
              <img
                className={styles.dropdownArrowSvgrepoCom1Icon}
                alt=""
                src="/dropdownarrowsvgrepocom-1.svg"
              />
              <div className={styles.bridge}>Learn</div>
              {learnButton && (
                <div className={styles.learnMenu}>
                  <Link
                    to="/about"
                    style={{ textDecoration: "none" }}
                    onClick={learnDropdown2}
                  >
                    <div className={styles.learnItem}>
                      About us
                      <hr className="solid" />
                    </div>
                  </Link>
                  <Link
                    to="/blog"
                    style={{ textDecoration: "none" }}
                    onClick={learnDropdown2}
                  >
                    <div className={styles.learnItem2}>Blog</div>
                  </Link>
                </div>
              )}
            </div>

            <div className={styles.dropdownArrowSvgrepoCom2Parent}>
              <Tooltip
                style={{ marginLeft: -10 }}
                interactive
                trigger="click"
                touchHold={true}
                stickyDuration={200}
                html={
                  <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
                    <strong style={{ fontFamily: "Inter" }}>
                      Coming soon...
                    </strong>
                  </div>
                }
              >
                <img
                  className={styles.dropdownArrowSvgrepoCom2Icon}
                  alt=""
                  src="/dropdownarrowsvgrepocom-1.svg"
                />
                <div className={styles.bridge}>Build</div>
              </Tooltip>
            </div>
            <Tooltip
              style={{ marginLeft: 100 }}
              interactive
              trigger="click"
              touchHold={true}
              stickyDuration={200}
              html={
                <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
                  <strong style={{ fontFamily: "Inter" }}>
                    Coming soon...
                  </strong>
                </div>
              }
            >
              <div className={styles.dropdownArrowSvgrepoCom3Parent}>
                <img
                  className={styles.dropdownArrowSvgrepoCom3Icon}
                  alt=""
                  src="/dropdownarrowsvgrepocom-1.svg"
                />
                <div className={styles.bridge}>Explore</div>
              </div>
            </Tooltip>

            <div className={styles.dropdownArrowSvgrepoCom3Parent4}>
              <div className={styles.bridge}>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "var(--color-darkslateblue)",
                  }}
                >
                  Ecosystem
                </Link>
              </div>
            </div>
          </div>
        </div>
      </div> */}
      {/* mobile nav */}
      <div style={{display:'flex',width:'100%',justifyContent:'space-between'}}>
        <div>
        <Link to="/"> <img className={styles.mobnavlogo} alt="" src="/complere_logo.svg" /></Link>
        </div>
        <div onClick={toggleNavbar} className={styles.ham}>
        <img
          className={styles.hamburgerMenuSvgrepoCom1Icon}
          alt=""
          src="/hamburger-menu.png"
        />
      </div>
      </div>
      <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
            />
      {isOpen && (
        <div className={styles.hamMenu}>
          <div onClick={mobLearnMenu} className={styles.menItem}>
            Learn
            <hr className="solid" />
          </div>
          <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#102c57" }}>
            <div className={styles.menItem}>
              Build
              <hr className="solid" />
            </div>
          </Link>
          <div onClick={notify} className={styles.menItem}>
            Explore
            <hr className="solid" />
          </div>
          <div className={styles.menItem}>
          <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "white",
              }}
            >Ecosystem
            </Link>
            <hr className="solid" />
          </div>
          <Link target="_blank" to='https://complere-bridge-test-deploy.vercel.app/?destinationChain=complere-chain&sourceChain=base-sepolia' style={{ textDecoration: "none",color:"#C71585" }}>
          <div  className={styles.menItem}>
            Bridge
            {/* <hr className="solid" /> */}
          </div>
          </Link>
        </div>
      )}
      {learnMob && (
        <div className={styles.moblearnMenu}>
          <Link to="/about"  onClick={mobLearnMenu}>
            {" "}
            <div style={{ marginTop: "-5px" }} className={styles.moblearnItem}>
              About us
              <hr style={{ marginTop: "20px" }} className="solid" />
            </div>
          </Link>
          <Link to="/blogpage" style={{ textDecoration: "none" }}  onClick={mobLearnMenu}>
          <div className={styles.moblearnItem}>Blog
          <hr style={{ marginTop: "15px" }} className="solid" /></div>
          </Link>
          <Link to="/faq" style={{ textDecoration: "none" }} >
                  <div style={{ marginTop: "5px" }} className={styles.moblearnItem}>FAQs</div>
          </Link>
        </div>
      )}
      
        {/* desktop nav */}
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div className={styles.nav}>
          <div className={styles.navtitle}>
          <Link to="/">
              <img alt="" src="/complere_logo.svg" />
            </Link>
          </div>
          <div className={styles.navSub}>

              <div className={styles.navText}>
              <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "var(--color-darkslateblue)",
                  }}
                >
                Ecosystem
                </Link>
              </div>
              <div onMouseEnter={learnDropdown}
                    onMouseLeave={learnDropdown2}
               className={styles.navText3}>
                Learn
                <img
                className={styles.dropdownArrowSvgrepoCom1Icn}
                alt=""
                src="/dropdownarrowsvgrepocom-1.svg"
              />
              {learnButton && (
                <div className={styles.learnMenu}>
                  <Link
                    to="/about"
                    style={{ textDecoration: "none" }}
                    onClick={learnDropdown2}
                  >
                    <div className={styles.learnItem}>
                      About us
                      <hr className="solid" />
                    </div>
                  </Link>
                  <Link
                    to="/blogpage"
                    style={{ textDecoration: "none" }}
                    onClick={learnDropdown2}
                  >
                    <div className={styles.learnItem2}>Blog
                    <hr className="solid" />
                    </div>
                  </Link>
                  <Link to="/faq" style={{ textDecoration: "none" }} >
              <div className={styles.learnItem2}>FAQs</div>
              </Link>
                </div>
              )}
              </div>
              {/* <Tooltip
                style={{ marginLeft: -10 }}
                interactive
                trigger="click"
                touchHold={true}
                stickyDuration={200}
                html={
                  <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
                    <strong style={{ fontFamily: "Inter" }}>
                      Coming soon...
                    </strong>
                  </div>
                }
              > */}
             <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#102c57" }}>

              <div className={styles.navText3}>
              
              Build
              <img
                className={styles.dropdownArrowSvgrepoCom1Icn}
                alt=""
                src="/dropdownarrowsvgrepocom-1.svg"
              />
              </div>
              </Link>
              {/* </Tooltip> */}
              <Tooltip
              style={{ marginLeft: 0 }}
              interactive
              trigger="click"
              touchHold={true}
              stickyDuration={200}
              html={
                <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
                  <strong style={{ fontFamily: "Inter" }}>
                    Coming soon...
                  </strong>
                </div>
              }
            >
              <div className={styles.navText3}>
              Explore
              <img
                className={styles.dropdownArrowSvgrepoCom1Icn}
                alt=""
                src="/dropdownarrowsvgrepocom-1.svg"
              />
              </div>
              </Tooltip>
              {/* <Tooltip
                style={{ marginLeft: 5 }}
                interactive
                trigger="click"
                touchHold={true}
                stickyDuration={200}
                html={
                  <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
                    <strong style={{ fontFamily: "Inter" }}>
                      Coming soon...
                    </strong>
                  </div>
                }
              > */}
             <Link target="_blank" to='https://complere-bridge-test-deploy.vercel.app/?destinationChain=complere-chain&sourceChain=base-sepolia' style={{ textDecoration: "none",color:"#C71585" }}>

              <div className={styles.navText2}>
                Bridge
                <img
                  className={styles.arrowUpRightFromSquareSvgIcn}
                  alt=""
                  src="/arrowuprightfromsquaresvgrepocom-1.svg"
                />
              </div>
              </Link>
              {/* </Tooltip> */}
          </div>
        </div>
      </div>

          <div className={styles.contentText}>
         <div className={styles.content}>
            <h2 style={{textAlign:'center',fontFamily:'rubik',fontWeight:500,fontSize:'42px'}}>About <i style={{color:'#fa58b6'}}>Complere</i></h2>
            <p className={styles.paratext} >
          Complere is the compliant RWA Layer 3 solution that facilitates
          seamless interactions between Web3 and traditional financial systems,
          ensuring that both institutional investors and everyday users can
          operate within a secure and legally compliant environment. It aims to
          transition asset tokenization from private blockchains to a secure,
          seamless, and permissionless Ethereum environment, enhancing
          blockchain capabilities with functionalities tailored to specific use
          cases and user needs.</p>
          <p className={styles.paratext}> As a Layer 3 chain, Complere integrates
          Ethereum's robust security with customized features to meet RWA
          requirements. Unlike permissioned chains that compromise security and
          decentralization, Complere offers a transparent, regulation-compliant
          environment, ensuring privacy through Account Abstraction and Zero
          Knowledge Proofs. Its flexibility includes both self-custodial and
          custodial solutions, making it accessible to a wide range of users.
          Additionally, its gasless transactions facilitate easy adoption and
          usage. By enabling interoperability with traditional financial systems
          and prioritizing compliance, Complere serves as a gateway for
          institutional adoption of tokenization and cryptocurrency through Real
          World Assets (RWA).
          </p>
          <p className={styles.paratext}> Complere, functioning as a Layer 3 solution
          ensures higher transaction throughput and quicker processing speeds
          for an improved user experience and platform performance. It operates
          on the BASE network, fostering seamless communication and value
          transfer among diverse blockchains and distributed ledger
          technologies, a crucial feature for Real World Assets (RWAs) to
          facilitate smooth movement of tokenized assets across multiple
          platforms and ecosystems.
        </p>
        <div style={{display:'flex',justifyContent:'center'}}>
        <img className={styles.about_img} src={'/aboutImg.svg'}></img>
        </div>

          </div>
          </div>
      
      {/* desktop footer */}
      <div className={styles.footerContent}>
      <div className={styles.footerEle}>
        <div className={styles.footerLeft}>
          <div className={styles.footerLHalf}>
          <Link to="/"><img alt="" src="/Complere-white.svg" /></Link>
          <div className={styles.wwwcomplerexyz}>The Compliant RWA Layer3 Chain</div>
          </div>
          <div className={styles.footerLHalf2}>
            <Link to='/about' style={{textDecoration:'none',color:"#fff"}}>
            <div className={styles.footerFeature}>About Us</div>
            </Link>
            <Link to='/' style={{textDecoration:'none',color:"#fff"}}>
            <div className={styles.footerFeature}>Features</div>
            </Link>
          </div>
          <div className={styles.footerLHalf2}>

            <div className={styles.footerFeature}>
            {/* <Tooltip
            interactive
            trigger="click"
            touchHold={true}
            stickyDuration={200}
            html={
              <div style={{ padding: 10, paddingBottom: 10 }}>
                <strong style={{ fontFamily: "Inter" }}>coming soon...</strong>
              </div>
            }
          > */}
          <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#FFFF" }}>
            Build
            </Link>
          {/* </Tooltip> */}
          </div>
            <Link to='/' style={{textDecoration:'none',color:"#fff"}}>
            <div className={styles.footerFeature}>Ecosystem</div>
            </Link>
          </div>
        </div>
        <div className={styles.footerRight}>
          <div>
          <a target="_blank" href="https://discord.gg/jjfmXJNhzS">
          <img
            className={styles.discordIcon}
            alt=""
            src="/discord-svgrepo-com.svg"
          />
        </a>
          </div>
          <div>
          <a target="_blank" href="https://www.instagram.com/complerechain/">
          <img
            className={styles.discordIcon}
            alt=""
            src="/instagram-img.svg"
          />
        </a>
          </div>
          <div>
          <a target="_blank" href="https://www.facebook.com/complere/">
          <img
            className={styles.discordIcon}
            alt=""
            src="/facebook-img.svg"
          />
        </a>
          </div>
          <div>
          <a target="_blank" href="https://twitter.com/complerechain/">
          <img
            className={styles.discordIcon}
            alt=""
            src="/icons8twitterx-1.svg"
          />
        </a>
          </div>
          <div>
          <a target="_blank" href="https://t.me/complerechain">
          <img
            className={styles.discordIcon}
            alt=""
            src="/telegram-svgrepo-com.svg"
          />
        </a>
          </div>
          <div>
          <a target="_blank" href="https://www.linkedin.com/company/complere-chain/">
          <img
            className={styles.discordIcon}
            alt=""
            src="/linked-in1.svg"
          />
        </a>
          </div>
          
        </div>
      </div>
      <hr className="solid" style={{width:'90%',marginTop:'-40px'}}/>
      <div className={styles.footerLow}>
        <div className={styles.footerText1}>© 2024 Complere Chain | All rights reserved</div>
        <div className={styles.footerLowRight}>
        <Link to="/endofuser" style={{textDecoration:'none',color:"#fff"}}>
          <div className={styles.footerText2}>End User Licence Agreement</div>
          </Link>
          <Link to="/privacy" style={{textDecoration:'none',color:"#fff"}}>
          <div className={styles.footerText}>Privacy Policy</div>
          </Link>
          <div onClick={() => window.location = 'mailto:hello@complere.xyz'} className={styles.footerText}>Contact Us</div>
        </div>
      </div>
      </div>
            {/* mobile footer */}
            <div className={styles.mobFooterContent}>
            <div className={styles.mobHeader}>
            <Link to="/"><img alt="" src="/Complere-white.svg" /></Link>
            </div>
            <div className={styles.wwwcomplerexyz}>The Compliant RWA Layer3 Chain</div>
            
            <div className={styles.mobele}>
              <div className={styles.mobItem}>
              <Link to='/about' style={{textDecoration:'none',color:"#fff"}}>
                <div className={styles.mobsubItem}>About Us</div>
                </Link>
                <Link to='/' style={{textDecoration:'none',color:"#fff"}}>
                <div className={styles.mobsubItem}>Features</div>
                </Link>
              </div>
              <div className={styles.mobItem}>
                <div className={styles.mobsubItem}>
                {/* <Tooltip
            interactive
            trigger="click"
            touchHold={true}
            stickyDuration={200}
            html={
              <div style={{ padding: 10, paddingBottom: 10 }}>
                <strong style={{ fontFamily: "Inter" }}>coming soon...</strong>
              </div>
            }
          > */}
            <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#FFFF" }}>
              Build
            </Link>
            {/* </Tooltip> */}
            </div>
                <Link to='/' style={{textDecoration:'none',color:"#fff"}}>
                <div className={styles.mobsubItem}>Ecosystem</div>
                </Link>
              </div>
            </div>
            <div className={styles.moblogo}>
           
          <div>
          <a target="_blank" href="https://www.facebook.com/complere/">
          <img
            className={styles.discordIcon}
            alt=""
            src="/facebook-img.svg"
          />
        </a>
          </div>
          <div>
          <a target="_blank" href="https://www.instagram.com/complerechain/">
          <img
            className={styles.discordIcon}
            alt=""
            src="/instagram-img.svg"
          />
        </a>
          </div>
          
          <div>
          <a target="_blank" href="https://twitter.com/complerechain/">
          <img
            className={styles.discordIcon}
            alt=""
            src="/icons8twitterx-1.svg"
          />
        </a>
          </div>
          <div>
          <a target="_blank" href="https://t.me/complerechain">
          <img
            className={styles.discordIcon}
            alt=""
            src="/telegram-svgrepo-com.svg"
          />
        </a>
          </div>
          <div>
          <a target="_blank" href="https://www.linkedin.com/company/complere-chain/">
          <img
            className={styles.discordIcon}
            alt=""
            src="/linked-in1.svg"
          />
        </a>
          </div>
          <div>
          <a target="_blank" href="https://discord.gg/jjfmXJNhzS">
          <img
            className={styles.discordIcon}
            alt=""
            src="/discord-svgrepo-com.svg"
          />
        </a>
          </div>
            </div>
            <div className={styles.moblowfoot}>© 2024 Complere Chain | All rights reserved</div>
            <hr className="solid" style={{width:'90%',marginTop:'10px'}}/>
            <Link to="/endofuser" style={{textDecoration:'none',color:"#fff"}}>
            <div className={styles.mobbottom}>End User Licence Agreement </div>
            </Link>
            <div className={styles.mobfootbottom}>
            <Link to="/privacy" style={{textDecoration:'none',color:"#fff"}}>
              <div className={styles.mobbottom}>Privacy Policy</div>
              </Link>
              <div onClick={() => window.location = 'mailto:hello@complere.xyz'} className={styles.mobbottom}>Contact Us</div>
            </div>
            </div>
            
    </div>
  );
};
export default About;
