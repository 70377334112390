import styles from "./GroupComponent.module.css";

const GroupComponent = () => {
  return (
    <div id="features" className={styles.featuresOfComplereChainParent}>
      <div className={styles.featuresOfComplereContainer}>
        <span className={styles.featuresOfComplereContainer1}>
          <span className={styles.featuresOf}>Features of</span>
          <span className={styles.span}>{` `}</span>
          <i className={styles.span}>Complere Chain</i>
        </span>
      </div>
      <div className={styles.groupParent}>
        <div className={styles.rectangleParent}>
          <div className={styles.groupChild} />
          <img
            className={styles.regulationSvgrepoCom1Icon}
            alt=""
            src="/regulationsvgrepocom-1.svg"
          />
        </div>
        <div className={styles.complereChainPrioritizesRegParent}>
          <div className={styles.complereChainPrioritizes}>
            Complere Chain prioritizes regulatory compliance, appealing to
            institutional investors and users seeking legal assurance for
            blockchain activities.
          </div>
          <div className={styles.regulationCompliant}>Regulation Compliant</div>
        </div>
      </div>
      <div className={styles.groupContainer}>
        <div className={styles.complereChainPrioritizesRegParent}>
          <div className={styles.complereChainPrioritizes}>
            Complere chain leads in security and privacy with zero-knowledge
            proofs, enabling transaction validation without revealing sensitive
            data.
          </div>
          <div className={styles.regulationCompliant}>Zero Knowledge Proof</div>
        </div>
        <img className={styles.groupItem} alt="" src="/group-33.svg" />
      </div>
      <div className={styles.groupDiv}>
        <div className={styles.complereChainPrioritizesRegParent}>
          <div className={styles.complereChainPrioritizes}>
            Complere chain's gasless feature removes transaction fees, boosting
            blockchain adoption for both users and developers.
          </div>
          <div className={styles.regulationCompliant}>Gasless</div>
        </div>
        <img className={styles.groupItem} alt="" src="/group-32.svg" />
      </div>
      <div className={styles.groupParent1}>
        <div className={styles.complereChainPrioritizesRegParent}>
          <div className={styles.complereChainPrioritizes}>
            Complere chain simplifies blockchain for mainstream users, removing
            complexities of crypto wallets and transactions.
          </div>
          <div className={styles.regulationCompliant}>Account Abstraction</div>
        </div>
        <div className={styles.rectangleParent}>
          <div className={styles.groupChild} />
          <img
            className={styles.accountSettingsSvgrepoCom1Icon}
            alt=""
            src="/accountsettingssvgrepocom-1.svg"
          />
        </div>
      </div>
      <div className={styles.complereChainsDualCustodyParent}>
        <div className={styles.complereChainsDual}>
          Complere chain's dual custody options cater to varied user
          preferences, offering both control and convenience.
        </div>
        <div className={styles.selfCustodialAs}>
          Self Custodial as well as Custodial Solutions
        </div>
        <div className={styles.rectangleContainer}>
          <div className={styles.groupChild} />
          <img
            className={styles.fistSvgrepoCom1Icon}
            alt=""
            src="/fistsvgrepocom-1.svg"
          />
        </div>
      </div>
    </div>
  );
};

export default GroupComponent;
