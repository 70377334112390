import Linkedin from "./Linkedin";
import Instagram from "./Instagram";
import styles from "./FrameComponent.module.css";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import { useNavigate, Link, useLocation } from "react-router-dom";
import {useRef} from 'react';
import FrameComponent2 from './FrameComponent2';
import { Link as ScrollLink} from 'react-scroll';

const FrameComponent = () => {
  const location = useLocation();
  const { hash, pathname, search } = location;
  console.log(pathname,'lsoslso')
  
  return (
    <div className={styles.vectorParent}>
      <img className={styles.frameChild} alt="" src="/vector-6.svg" />
      <div className={styles.complereChain}>
        © 2024 Complere Chain | All rights reserved
      </div>
      <Link to="/endofuser" >
        <span className={styles.endUserLicence}>
          End User Licence Agreement
        </span>
      </Link>
      <Link to="/privacy" >
        <div className={styles.privacyPolicy}>Privacy Policy</div>
      </Link>

      <div onClick={() => window.location = 'mailto:hello@complere.xyz'} className={styles.contactUs}>
          Contact Us
      </div>
      <div className={styles.groupWrapper}>
       
        <Link to="/"><img alt="" src="/Complere-white.svg" /></Link>
      </div>
      <div className={styles.desktop}>
        <a
          target="_blank"
          href="https://www.linkedin.com/company/complere-chain/"
        >
          <Linkedin
            linkedIn="/linked-in.svg"
            linkedinWidth="42px"
            linkedinHeight="42px"
            linkedinPosition="absolute"
            linkedinTop="178px"
            linkedinLeft="1268px"
            bgHeight="87.62%"
            bgWidth="87.62%"
            bgTop="6.19%"
            bgRight="6.19%"
            bgBottom="6.19%"
            bgLeft="6.19%"
            bgBorderRadius="73.5px"
            linkedInIconHeight="43.81%"
            linkedInIconWidth="46.9%"
            linkedInIconBottom="31.19%"
            linkedInIconLeft="28.1%"
          />
        </a>
        <a target="_blank" href="https://t.me/complerechain">
          <Instagram
            instagram="/telegram-svgrepo-com.svg"
            instagramWidth="42px"
            instagramHeight="42px"
            instagramPosition="absolute"
            instagramTop="178px"
            instagramLeft="1326px"
            bgHeight="87.62%"
            bgWidth="87.62%"
            bgTop="6.19%"
            bgRight="6.19%"
            bgBottom="6.19%"
            bgLeft="6.19%"
            bgHeight1="87.62%"
            bgWidth1="87.62%"
            bgTop1="6.19%"
            bgRight1="6.19%"
            bgBottom1="6.19%"
            bgLeft1="6.19%"
            bgHeight2="87.62%"
            bgWidth2="87.62%"
            bgTop2="6.19%"
            bgRight2="6.19%"
            bgBottom2="6.19%"
            bgLeft2="6.19%"
            instagramIconHeight="92.62%"
            instagramIconWidth="92.62%"
            instagramIconTop="4.81%"
            instagramIconRight="18.57%"
            instagramIconBottom="18.57%"
            instagramIconLeft="14.81%"
          />
        </a>
        <a target="_blank" href="https://discord.gg/jjfmXJNhzS">
          <img
            className={styles.facebookIcon}
            alt=""
            src="/discord-svgrepo-com.svg"
          />
        </a>
        <a target="_blank" href="https://www.instagram.com/complerechain/">
          <img
            className={styles.newInstaIcon}
            alt=""
            src="/instagram-img.svg"
          />
        </a>
        <a target="_blank" href="https://www.facebook.com/complere/">
          <img
            className={styles.newFacebookIcon}
            alt=""
            src="/facebook-img.svg"
          />
        </a>
        <div className={styles.icons8Twitterx1Wrapper}>
          <a target="_blank" href="https://twitter.com/complerechain">
            <img
              className={styles.icons8Twitterx1}
              alt=""
              src="/icons8twitterx-1.svg"
            />
          </a>
        </div>
      </div>
      <div className={styles.mobLogo}>
          <a
          target="_blank"
          href="https://www.facebook.com/complere/"
          >
            <img
            className={styles.mobface}
            alt=""
            src="/facebook-img.svg"
          />
          </a>
          <a
          target="_blank"
          href="https://www.instagram.com/complerechain/"
          >
            <img
            className={styles.mobface}
            alt=""
            src="/instagram-img.svg"
          />
          </a>
          <a
          target="_blank"
          href="https://twitter.com/complerechain"
          >
            <img
            className={styles.mobface}
            alt=""
            src="/icons8twitterx-1.svg"
          />
          </a>
          <a
          target="_blank"
          href="https://t.me/complerechain"
          >
            <img
            className={styles.mobface}
            alt=""
            src="/telegram-svgrepo-com.svg"
          />
          </a>
          <a
          target="_blank"
          href="https://www.linkedin.com/company/complere-chain/"
          >
            <img
            alt=""
            src="/linked-in.svg"
          />
          </a>
         
          <div>
          <a
          target="_blank"
          href="https://discord.gg/jjfmXJNhzS"
          >
            <img
            className={styles.mobface}
            alt=""
            src="/discord-svgrepo-com.svg"
          />
          </a>
          </div>
          <div>
          
          </div>
            
      </div>
      <div className={styles.learnParent}>
        
        <div className={styles.ecosystem}>
            {pathname !== '/'? 
          <Link to="/" style={{textDecoration:'none',color:"#fff"}}>Ecosystem</Link>
          :
            <ScrollLink
                to="hero" 
                spy={true} 
                smooth={true} 
                offset={30} 
                duration={500} 
              >
                Ecosystem
              </ScrollLink>
            }
        </div>
        <div className={styles.aboutUs}>
          <Link reloadDocument to="/about" style={{textDecoration:'none',color:"#fff"}}>
            About Us
          </Link>
        </div>
        <div className={styles.features}>
          
          {pathname !== '/'? 
          <Link to="/" style={{textDecoration:'none',color:"#fff"}}>Features</Link>
          :
          <ScrollLink
          to="features" 
          spy={true} 
          smooth={true} 
          offset={-30} 
          duration={500} 
        >
          Features
      </ScrollLink>
        }
            
        </div>
        <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#FFFF" }}>
        <div className={styles.build}>
         
            Build
        </div>
        </Link>
      </div>
      <div className={styles.wwwcomplerexyz}>The Compliant RWA Layer3 Chain</div>
    </div>
  );
};

export default FrameComponent;
