import styles from "./PartnersComponent.module.css";
import Marquee from "react-fast-marquee";

const PartnersComponent = () => {
  return (
    <div >
      <Marquee style={{width:'1588px !important',minwidth:'!important'}} className={styles.groupParent16} pauseOnHover={true}>
      <img className={styles.frameChild13} alt="bankBaroda" src="/bankBaroda.png" />
      <img className={styles.frameChild14} alt="indianBank" src="/indianBank.png" />
      {/* <div className={styles.clipPathGroupParent}>
        <img
          className={styles.clipPathGroup}
          alt=""
          src="/clip-path-group.svg"
        />
        <img className={styles.groupChild10} alt="" src="/group-369.svg" />
        <img className={styles.groupChild11} alt="" src="/group-368.svg" />
        <div className={styles.aGovernmentUndertaking}>
          (A Government Undertaking)
        </div>
      </div> */}
      {/* <div className={styles.theSataraDccBankLtdParent}>
        <b className={styles.theSataraDcc}>THE SATARA DCC BANK LTD.</b>
        <img
          className={styles.unnamedRemovebgPreview11}
          alt=""
          src="/unnamedremovebgpreview-1-1@2x.png"
        />
      </div> */}
      <img className={styles.frameChild80} alt="masterCard" src="/masterCard.png" />
      <img className={styles.frameChild81} alt="mscBank" src="/mscBank.png" />
      <img className={styles.frameChild82} alt="mscw" src="/mscw.png" />
      <img className={styles.frameChild83} alt="sataraBank" src="/sataraBank.png" />
      <img className={styles.frameChild84} alt="tvs" src="/tvs.png" />
      <img className={styles.frameChild85} alt="unionBank" src="/unionBank.png" />
      </Marquee>
    </div>
  );
};

export default PartnersComponent;
