import styles from "./Blog1.module.css";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
// import FrameComponent from "../components/FrameComponent";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { ToastContainer, toast } from "react-toastify";
// import Linkedin from "../components/Linkedin";
// import Instagram from "../components/Instagram";

const Blog1 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [learnButton, setLearnButton] = useState(false);
  const [learnMob, setLearnMob] = useState(false);

  const location = useLocation();
  const { hash, pathname, search } = location;
  console.log(pathname, "lsoslso");

  const learnDropdown = () => {
    setLearnButton(true);
  };
  const learnDropdown2 = () => {
    setLearnButton(false);
  };
  const toggleNavbar = () => {
    console.log("tooclcl");
    setIsOpen(!isOpen);
    if (learnMob) {
      setLearnMob(false);
    }
  };
  const notify = () => {
    toast.success("Coming soon!");
  };
  const mobLearnMenu = () => {
    setLearnMob(!learnMob);
    setIsOpen(false);
  };
  console.log(learnButton, "learnButton", isOpen, "lmon", learnMob);

  return (
    <div className={styles.desktop1}>
      {/* mobile nav */}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Link to="/">
            {" "}
            <img
              className={styles.mobnavlogo}
              alt=""
              src="/complere_logo.svg"
            />
          </Link>
        </div>
        <div onClick={toggleNavbar} className={styles.ham}>
          <img
            className={styles.hamburgerMenuSvgrepoCom1Icon}
            alt=""
            src="/hamburger-menu.png"
          />
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      {isOpen && (
        <div className={styles.hamMenu}>
          <div onClick={mobLearnMenu} className={styles.menItem}>
            Learn
            <hr className="solid" />
          </div>
          <Link
            target="_blank"
            to="https://complere.gitbook.io/complere-chain-core-docs"
            style={{ textDecoration: "none", color: "#FFFF" }}
          >
            <div className={styles.menItem}>
              Build
              <hr className="solid" />
            </div>
          </Link>
          <div onClick={notify} className={styles.menItem}>
            Explore
            <hr className="solid" />
          </div>
          <div className={styles.menItem}>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "white",
              }}
            >
              Ecosystem
            </Link>
            <hr className="solid" />
          </div>
          <Link
            target="_blank"
            to="https://complere-bridge-test-deploy.vercel.app/?destinationChain=complere-chain&sourceChain=base-sepolia"
            style={{ textDecoration: "none", color: "#C71585" }}
          >
            <div className={styles.menItem}>
              Bridge
              {/* <hr className="solid" /> */}
            </div>
          </Link>
        </div>
      )}
      {learnMob && (
        <div className={styles.moblearnMenu}>
          <Link to="/about" onClick={mobLearnMenu}>
            {" "}
            <div style={{ marginTop: "-5px" }} className={styles.moblearnItem}>
              About us
              <hr style={{ marginTop: "20px" }} className="solid" />
            </div>
          </Link>
          <Link
            to="/blogpage"
            style={{ textDecoration: "none" }}
            onClick={mobLearnMenu}
          >
            <div className={styles.moblearnItem}>Blog</div>
          </Link>
        </div>
      )}
      {/* desktpo nav */}
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div className={styles.nav}>
          <div className={styles.navtitle}>
            <Link to="/">
              <img alt="" src="/complere_logo.svg" />
            </Link>
          </div>
          <div className={styles.navSub}>
            <div className={styles.navText}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "var(--color-darkslateblue)",
                }}
              >
                Ecosystem
              </Link>
            </div>
            <div
              onMouseEnter={learnDropdown}
              onMouseLeave={learnDropdown2}
              className={styles.navText3}
            >
              Learn
              <img
                className={styles.dropdownArrowSvgrepoCom1Icn}
                alt=""
                src="/dropdownarrowsvgrepocom-1.svg"
              />
              {learnButton && (
                <div className={styles.learnMenu}>
                  <Link
                    to="/about"
                    style={{ textDecoration: "none" }}
                    onClick={learnDropdown2}
                  >
                    <div className={styles.learnItem}>
                      About us
                      <hr className="solid" />
                    </div>
                  </Link>
                  <Link
                    to="/blogpage"
                    style={{ textDecoration: "none" }}
                    onClick={learnDropdown2}
                  >
                    <div className={styles.learnItem2}>
                      Blog
                      <hr className="solid" />
                    </div>
                  </Link>
                  <Link to="/faq" style={{ textDecoration: "none" }}>
                    <div className={styles.learnItem2}>FAQs</div>
                  </Link>
                </div>
              )}
            </div>

            <Link
              target="_blank"
              to="https://complere.gitbook.io/complere-chain-core-docs"
              style={{ textDecoration: "none", color: "#102c57" }}
            >
              <div className={styles.navText3}>
                Build
                <img
                  className={styles.dropdownArrowSvgrepoCom1Icn}
                  alt=""
                  src="/dropdownarrowsvgrepocom-1.svg"
                />
              </div>
            </Link>
            <Tooltip
              style={{ marginLeft: 0 }}
              interactive
              trigger="click"
              touchHold={true}
              stickyDuration={200}
              html={
                <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
                  <strong style={{ fontFamily: "Inter" }}>
                    Coming soon...
                  </strong>
                </div>
              }
            >
              <div className={styles.navText3}>
                Explore
                <img
                  className={styles.dropdownArrowSvgrepoCom1Icn}
                  alt=""
                  src="/dropdownarrowsvgrepocom-1.svg"
                />
              </div>
            </Tooltip>
            {/* <Tooltip
              style={{ marginLeft: 5 }}
              interactive
              trigger="click"
              touchHold={true}
              stickyDuration={200}
              html={
                <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
                  <strong style={{ fontFamily: "Inter" }}>
                    Coming soon...
                  </strong>
                </div>
              }
            > */}
            <Link
              target="_blank"
              to="https://complere-bridge-test-deploy.vercel.app/?destinationChain=complere-chain&sourceChain=base-sepolia"
              style={{ textDecoration: "none", color: "#C71585" }}
            >
              <div className={styles.navText2}>
                Bridge
                <img
                  className={styles.arrowUpRightFromSquareSvgIcn}
                  alt=""
                  src="/arrowuprightfromsquaresvgrepocom-1.svg"
                />
              </div>
            </Link>
            {/* </Tooltip> */}
          </div>
        </div>
      </div>

      <div className={styles.contentText}>
        <div className={styles.content} id="intro">
          <p style={{ color: "white", fontFamily: "inter", fontSize: "16px" }}>
            Learn &gt;
            <Link
              to="/blogpage"
              style={{
                color: "white",
                textDecoration: "none",
                fontFamily: "inter",
                fontSize: "16px",
              }}
            >
              {" "}
              Blogs
            </Link>{" "}
            &gt;{" "}
            <Link
              to="/blog/introduction-to-complere-chain"
              style={{
                color: "white",
                textDecoration: "none",
                fontFamily: "inter",
                fontSize: "16px",
              }}
            >
              Introduction to Complere Chain
            </Link>
          </p>
          <h2
            className={styles.introBlog}
            // style={{
            //   textAlign: "left",
            //   fontFamily: "inter",
            //   fontWeight: 400,
            //   fontSize: "42px",
            //   color: "#ffff",
            // }}
          >
            Introduction to Complere Chain
          </h2>
          <p className={styles.introPara}>
            Published on : 29 May 2024&nbsp;&nbsp;&nbsp;|&nbsp;
            <span>&nbsp; 20 min read</span>
          </p>
        </div>
        {/* con */}
      </div>

      <div className={styles.paradiv}>
        <div className={styles.contentNavigation}>
          <div className={styles.subcontentNavigation}>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="intro"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                Complere Chain-Intro
              </ScrollLink>
            </div>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="history"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                History & present of Tokenisation
              </ScrollLink>
            </div>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="market"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                {" "}
                Market Growth
              </ScrollLink>
            </div>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="complere"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                Complere Chain
              </ScrollLink>
            </div>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="key"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                Key Features of Complere
              </ScrollLink>
            </div>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="layer"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                Layer 3 — Our Choice of Architecture
              </ScrollLink>
            </div>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="security"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                Security of Ethereum
              </ScrollLink>
            </div>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="lindy"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                Lindy Effect of Ethereum
              </ScrollLink>
            </div>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="use"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                Use Case Specific Layer 3 Solutions
              </ScrollLink>
            </div>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="eco"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                Complere Ecosystem
              </ScrollLink>
            </div>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="road"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                Complere Roadmap
              </ScrollLink>
            </div>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="build"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                Why Build on Complere?
              </ScrollLink>
            </div>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="involved"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                Get Involved
              </ScrollLink>
            </div>
          </div>
        </div>
        <div className={styles.allPara}>
          <p className={styles.contentPara} id="intro">
            The financial industry is undergoing a major transformation, thanks
            to the rapid advancements in blockchain technology. This evolution
            is paving the way for groundbreaking solutions that bridge the gap
            between Web2 and Web3, with one of the most exciting developments
            being the tokenization of Real World Assets (RWAs). Tokenizing RWAs
            is revolutionizing how we view and handle tangible assets in our
            increasingly digital world, merging the realms of traditional
            finance (TradFi) and decentralized finance (DeFi).
          </p>
          <p className={styles.contentPara}>
            {" "}
            Real World Assets (RWAs) represent tangible items such as real
            estate, commodities, and securities that can be digitized and traded
            on blockchain networks. The concept of RWAs holds immense promise,
            as it enables fractional ownership, increases liquidity, and
            broadens access to investment opportunities. However, the
            tokenization of RWAs also presents numerous challenges. The strict
            regulatory standards for handling such assets necessitate a robust,
            secure, and compliant blockchain infrastructure.
          </p>
          <h2 className={styles.bold} id="history">
            History and present of Tokenisation{" "}
          </h2>
          <p className={styles.contentPara}>
            To date, there have been numerous digital representations of
            real-world assets, many predating blockchain technology. Among the
            most recognizable forms of tokenized assets are Real Estate
            Investment Trusts (REITs) for real estate and Exchange-Traded Funds
            (ETFs) for commodities. These financial instruments effectively
            digitize these asset classes, transforming them into highly
            investable segments of the economy.
          </p>
          <p className={styles.contentPara}>
            Prior to blockchain, tokenization was almost exclusively associated
            with financial instruments. The success of ETFs and REITs
            demonstrated that digitizing transaction mediums could significantly
            improve liquidity and access. However, without distributed computing
            and standardized consensus, tokenization relied on third-party
            database management, limiting its applicability. This changed with
            the advent of decentralized blockchains in the early 2010s.
          </p>
          <p className={styles.contentPara}>
            Smart contracts, first conceived by computer scientist Nick Szabo in
            1994, became widely accessible with Ethereum’s launch in 2015. These
            self-executing agreements, written in code, allow for the
            verification, transfer, and management of asset ownership without
            intermediaries. This automation began to reduce frictions in
            traditional asset management and laid the groundwork for the
            tokenization of real-world assets.
          </p>
          <p className={styles.contentPara}>
            Between 2016 and 2019, interest in real-world asset (RWA)
            tokenization grew, with projects like MakerDAO exploring this area.
            Companies like Broadridge facilitated over $1 trillion worth of
            tokenized repurchase agreements monthly on its Distributed Ledger
            Repo (DLR) platform, indicating the scale of adoption. SWIFT
            conducted experiments demonstrating that its infrastructure could
            facilitate the transfer of tokenized value across multiple
            blockchains, highlighting the potential for private blockchains to
            streamline operations. Private blockchains became dominant over
            public blockchains in tokenization as they offer more control,
            faster transactions, and enhanced scalability, making them
            attractive for businesses and organizations. Private blockchains
            like Hyperledger Fabric and Corda were in demand, offering a more
            controlled environment for tokenization initiatives. They provide a
            closed network where only specific participants have access,
            ensuring greater security and privacy compared to public
            blockchains. Efforts in the tokenization industry on private
            blockchains during this period focused on creating and managing
            tokens within closed networks, addressing limitations in
            tokenization opportunities, and exploring governance challenges.
          </p>
          <p className={styles.contentPara}>
            In 2018, ERC-1400 tokens pioneered regulatory-compliant
            securitization by standardizing methods for issuing, transferring,
            and managing tokens within a decentralized network. However, the
            concept of tokenized real-world assets did not gain significant
            traction in the crypto world until recently. In 2020 due to covid,
            this sector took a backseat and since 2021, there has been a
            significant surge in the tokenization industry, with major players
            showing heightened interest and commitment in tokenisation on public
            blockchains. Recently, Ondo's OUSG fund, now fully backed by BUIDL,
            has tokenized BlackRock's U.S. T-bills fund on Ethereum.
            Additionally, Franklin Templeton has tokenized its U.S. Government
            Money Fund, represented by the $BENJI token, on Polygon and Stellar,
            holding the largest market share at 33.6% or $360.2 million in
            assets. Other public blockchains, such as Avalanche, Gnosis, and
            Base, are also used by some tokenized Treasury funds, though they
            maintain smaller market shares.
          </p>
          <p className={styles.contentPara}>
            Notably, BlackRock, the world's largest asset manager, made a foray
            into the RWA tokenization space. Their introduction of BUIDL, a
            tokenized fund launched on the Ethereum network, offered investors
            stable value and daily accrued dividends, solidifying the industry's
            credibility. Moreover, the endorsement of RWA tokenization by
            industry giants like Anchorage Digital Bank NA, BitGo, Coinbase, and
            Fireblocks further legitimized the sector. Major financial
            institutions such as JP Morgan, HSBC, Bank of America, and
            investment funds like Invesco and Franklin Templeton began actively
            discussing and investing in RWA tokenized assets across various
            domains including bonds, commodities, and real estate.
          </p>
          <h2 className={styles.bold} id="market">
            Market Growth
          </h2>
          <p className={styles.contentPara}>
            As of 2024, the total value locked (TVL) in tokenized RWAs has
            already surpassed $6.5 billion, demonstrating rapid growth in this
            sector. The convergence between crypto and traditional asset
            classes, including fiat currencies, equities, government bonds, and
            real estate, is experiencing unprecedented growth. The market is
            witnessing increased interest from institutional investors, with
            many major financial firms exploring tokenization as a means to
            unlock new investment opportunities and improve operational
            efficiencies.
          </p>
          <p className={styles.contentPara}>
            Larry Fink, CEO of BlackRock, has described tokenization as “the
            next generation of securities.” A report from the Boston Consulting
            Group predicts that the market could reach $16 trillion. So, the
            market for tokenized assets could grow to as large as to become a
            multi-trillion dollar industry in this decade as traditional
            financial (TradFi) institutions continue to adopt blockchain
            technology.
          </p>
          <p className={styles.contentPara}>
            This report projects that even using a conservative methodology,
            asset tokenization would be a US$16.1 trillion business opportunity
            by 2030. In a best-case scenario, that estimate goes up to US$68
            trillion
          </p>
          <div className={styles.img_blog}>
            <img alt="" className={styles.logoImg} src="/asset-tokenization.jpg" />
          </div>
          <p className={styles.contentPara}>
            The projected growth in the tokenization of assets is driven by
            demand from a wide range of investors for greater access to private
            markets. Tokenization and fractionalization of assets lower barriers
            to investment in private markets by sharply reducing minimum lot
            sizes. Assets being fractionalized and tokenized on platforms can
            reduce minimum investment sizes from millions of dollars to just
            thousands of dollars. Previously investments of this kind were only
            available to institutions.
          </p>
          <p className={styles.contentPara}>
            Tokenized investments can also be effectively ‘borderless’, allowing
            investors around the world to invest in markets they were previously
            unable to access. Historically, many of the world’s assets have been
            held in illiquid formats, with past studies estimating the share of
            illiquid assets at more than 50% of overall assets. Illiquid assets
            face challenges such as imperfect price discovery and trading
            discounts compared to liquid assets, the report said.
          </p>
          <p className={styles.contentPara}>
            Tokenization creates liquidity by making it easier for the assets to
            be distributed and traded among investors. The report by BCG lists
            five indications that asset tokenization may be on the cusp of wide
            global adoption:
          </p>
          <p className={styles.contentPara}>
            <ul>
              <li>increased trading volume in tokenized assets</li>
              <li>strengthening stakeholder sentiment across many countries</li>
              <li>recognition among monetary authorities and regulators</li>
              <li>more asset classes being tokenized</li>
              <li>
                a growing pool of active developer talent in the blockchain
                space
              </li>
            </ul>
          </p>
          <p className={styles.contentPara}>
            While the concept of asset fractionalization has been around for
            some time, its impact has hitherto been felt mainly in the public
            markets, with structures such as fractional shares, ETFs and public
            REITs. In recent years, there has been a significant pivot with the
            emergence of asset tokenization players that apply blockchain
            technology to private markets and alternative assets.
          </p>
          <p className={styles.contentPara}>
            Globally, growth in tokenized assets is expected in real estate,
            equities, bonds and investment funds, as well as less traditional
            assets such as car fleets and patentsiv. With a 50-fold increase
            predicted between 2022 and 2030, from US$310 billion to US$16.1
            trillion, tokenized assets are expected to make up 10% of global GDP
            by the end of the decade.
          </p>
          <p className={styles.contentPara}>
            In anticipation of a more widespread acceptance of asset
            tokenization, the BCG report makes several recommendations to
            current and potential stakeholders. For example, financial
            institutions might consider finding ways to pilot and deploy asset
            tokenization projects by upgrading existing business models, rather
            than looking to replace them.
          </p>
          <p className={styles.contentPara}>
            An example of this trend is US government bonds. In 2023, the
            tokenization of these assets grew 450%, making it the largest asset
            class to be tokenized over the past year. This is just one example
            of where the market is already moving. Singapore’s central bank has
            started testing tokenization with established financial institutions
            and working on a tokenized government bond fund. Asian countries
            such as Hong Kong, Japan, Singapore, the Philippines, and European
            countries such as Germany, Luxembourg, France, and Switzerland have
            tested issuing digital bonds as well. To date, only about $4 billion
            of tokenized bonds have been issued with the traditional global bond
            market valued at $130 trillion.
          </p>
          <p className={styles.contentPara}>
            Most of the growth today come from other assets that are not fiat
            currencies as can be expected. The current RWA market is dominated
            by the tokenization of fiat in the form of stablecoins. As this
            sector is almost saturated, future growth will come from other
            real-world assets being tokenized and as blockchain continues to
            evolve, tokenization is anticipated to play an increasingly pivotal
            role in revolutionizing traditional asset ownership and trading.
          </p>
          <p className={styles.contentPara}>
            However, despite the promising prospects, the current state of
            real-world asset tokenization confronts significant challenges
            primarily stemming from the predominance of private blockchain
            platforms. While private blockchains offer certain benefits, they
            often lack the robust security and composable liquidity provided by
            public blockchains. Being inherently centralized and reliant on a
            select group of validators, private blockchains are susceptible to
            security vulnerabilities. Additionally, tokenized assets on private
            blockchains suffer from interoperability issues, existing in
            isolated silos that hinder the creation of composable liquidity
            pools. Moreover, the current blockchain landscape falls short of
            being permissionless, secure, and decentralized, all of which are
            imperative for successful RWA tokenization.
          </p>
          <p className={styles.contentPara}>
            With an anticipated influx of multi-billion investment and capital
            in Real World Assets (RWAs) and considerable interest from major
            industry players, such as BlackRock and JP Morgan, selecting the
            right infrastructure becomes paramount. The inadequacies of current
            private blockchain solutions underscore the pressing need for a more
            advanced alternative — a technology solution that brings the
            benefits of public blockhain’s security layer to the institutional
            requirement of compliance, privacy, data protection and better user
            experience. This is precisely where Complere steps in to address
            these challenges by creating a layer 3 chain that will abstract away
            the complexities of tokenisation in a compliant fashion with the
            security of Ethereum chain.
          </p>
          {/* <p className={styles.contentPara}>
            However, despite the promising prospects, the current state of
            real-world asset tokenization confronts significant challenges
            primarily stemming from the predominance of private blockchain
            platforms. While private blockchains offer certain benefits, they
            often lack the robust security and composable liquidity provided by
            public blockchains. Being inherently centralized and reliant on a
            select group of validators, private blockchains are susceptible to
            security vulnerabilities. Additionally, tokenized assets on private
            blockchains suffer from interoperability issues, existing in
            isolated silos that hinder the creation of composable liquidity
            pools. Moreover, the current blockchain landscape falls short of
            being permissionless, secure, and decentralized, all of which are
            imperative for successful RWA tokenization. With an anticipated
            influx of multi-billion investment and capital in Real World Assets
            (RWAs) and considerable interest from major industry players, such
            as BlackRock and JP Morgan, selecting the right infrastructure
            becomes paramount. The inadequacies of current private blockchain
            solutions underscore the pressing need for a more advanced
            alternative — a technology solution that brings the benefits of
            public blockhain’s security layer to the institutional requirement
            of{" "}
            <span style={{ fontWeight: 600, color: "black" }}>
              compliance, privacy, data protection and better user experience
            </span>
            . This is precisely where Complere steps in to address these
            challenges by creating a layer 3 chain that will abstract away the
            complexities of tokenisation in a compliant fashion with the
            security of Ethereum chain.
          </p> */}
          <h2 className={styles.bold} id="complere">
            Complere Chain
          </h2>
          <p className={styles.contentPara}>
            Complere Chain facilitates cross-chain interoperability. Assets
            tokenized on Complere can seamlessly interact with other public
            blockchains, DeFi protocols, and liquidity pools. This
            interoperability ensures composable liquidity, enabling efficient
            asset utilization. Positioned as a Layer 3 chain on a Base chain,
            Complere combines Ethereum's robust security with customized
            features to meet RWA requirements. Unlike permissioned chains that
            compromise on security and decentralization, Complere provides a
            transparent, regulation-compliant environment, ensuring privacy
            through Account Abstraction and Zero-Knowledge Proofs. Its
            flexibility includes both self-custodial and custodial solutions,
            making it accessible to a broad range of users. Furthermore, its
            gasless transactions facilitate seamless adoption and usage. By
            enabling interoperability with traditional financial systems and
            prioritizing compliance, Complere serves as a gateway for
            institutional adoption of tokenization and cryptocurrency through
            Real World Assets (RWA).
          </p>
          <div className={styles.img_blog}>
            <img alt="" className={styles.logoImg} src="/Comp_Blog_logo.png" />
          </div>
          <p className={styles.contentPara}>
            Complere is the compliant RWA Layer 3 solution that enables seamless
            interactions between Web3 and traditional financial systems and
            ensures that both institutional investors and everyday users can
            operate within a secure and legally compliant environment
            seamlessly. It is creating a chain to move asset tokenization from
            private blockchains to a secure, seamless, and permissionless
            Ethereum environment, enhancing blockchain capabilities with
            functionalities tailored to specific use cases and user needs.
          </p>
          <p className={styles.contentPara}>
            Complere Chain is a game-changer for startups in the Real World
            Assets (RWA) tokenization space, providing them with a robust and
            versatile platform to build and scale their offerings. By
            facilitating cross-chain interoperability, Complere enables seamless
            interactions between assets tokenized on its platform and other
            public blockchains, DeFi protocols, and liquidity pools. This
            interoperability not only expands the reach and accessibility of
            these assets but also ensures composable liquidity, allowing
            startups to optimize their asset management strategies and enhance
            market access for a broader range of investors. Moreover, Complere's
            positioning as a Layer 3 chain on a Base chain, combining Ethereum's
            robust security with tailored features to meet RWA requirements,
            offers startups a secure and transparent environment for their
            tokenization activities. This, in turn, builds trust among investors
            and stakeholders, crucial for the successful growth of startups in
            the RWA space.
          </p>
          <p className={styles.contentPara}>
            Complere's flexibility in offering both self-custodial and custodial
            solutions caters to a diverse set of users, making it more
            accessible and user-friendly for startups. The gasless transactions
            facilitated by Complere also streamline adoption and usage, reducing
            barriers to entry and enhancing the overall user experience. By
            enabling startups to tokenize real-world assets efficiently and
            compliantly, Complere acts as a gateway for institutional adoption
            of tokenization and cryptocurrency through RWAs, opening up new
            avenues for startups to attract institutional investors and
            fostering a more inclusive and comprehensive financial ecosystem
          </p>
          <h2 className={styles.bold} id="key">
            Key Features of Complere
          </h2>
          <h4 className={styles.bold}>Regulatory Compliance</h4>
          <p className={styles.contentPara}>
            Complere prioritizes adherence to legal standards, which is crucial
            for institutional investors and users navigating financial
            regulations. This compliance mitigates legal risks, ensuring that
            all transactions and interactions meet necessary legal requirements.
          </p>
          <h4 className={styles.bold}>Efficient Transaction Processing</h4>
          <p className={styles.contentPara}>
            To optimize efficiency, Complere leverages Layer 2 solutions. This
            approach reduces the gas fees typically associated with Layer 1
            blockchains, making transactions faster and more cost-effective.
          </p>
          <h4 className={styles.bold}>Zero Knowledge Proof Technology</h4>
          <p className={styles.contentPara}>
            Security and privacy are paramount in blockchain interactions. The
            privacy of transactions, especially in institutional settings, is a
            critical aspect that intertwines with data protection measures to
            ensure the confidentiality, integrity, and security of sensitive
            information. Where financial data and transactions are paramount,
            robust data protection practices play a pivotal role in safeguarding
            the privacy of transactions. Complere utilizes Zero Knowledge Proof
            technology to protect sensitive information related to RWAs. This
            ensures that transactions remain secure and private without
            compromising on transparency.
          </p>
          <h4 className={styles.bold}>Simplified Blockchain Interactions</h4>
          <p className={styles.contentPara}>
            Complere simplifies blockchain usage through account abstraction
            features, making it accessible to mainstream users. This innovation
            abstracts the complexities of blockchain accounts, facilitating
            easier adoption and interaction.
          </p>
          <h4 className={styles.bold}>Flexible Custody Options</h4>
          <p className={styles.contentPara}>
            Understanding that users have different preferences for asset
            control, Complere offers a range of custody options. Whether users
            prefer self-custodial solutions or trust custodial services,
            Complere provides the necessary flexibility.
          </p>
          <p className={styles.contentPara}>
            Complere is a Layer 3 chain built on the BASE network, which offers
            and facilitates seamless communication and value transfer between
            different blockchains and distributed ledger technologies. Such
            interoperability is essential for Real World Assets (RWAs), as it
            allows tokenized assets to move smoothly across multiple platforms
            and ecosystems.
          </p>
          <div className={styles.img_blog}>
            <img alt="" className={styles.logoImg} src="/complere_arch.png" />
          </div>
          <h2 className={styles.bold} id="layer">
            Layer 3 — Our Choice of Architecture{" "}
          </h2>
          <p className={styles.contentPara}>
            Layer 3 solutions like Complere are considered ideal for building
            platforms that handle Real World Assets (RWA) due to several
            compelling reasons.
          </p>
          <p className={styles.contentPara}>
            Firstly, Layer 3 chains operate on top of existing Layer 1 and Layer
            2 networks, leveraging the security and decentralization of Layer 1,
            like Ethereum, while also providing the flexibility to introduce
            features and optimizations specific to the application’s needs.
          </p>
          <p className={styles.contentPara}>
            For RWA, this means that Layer 3 platforms can offer customized
            solutions that are tailored to the unique requirements of tokenizing
            and managing real-world assets. This includes the ability to handle
            complex compliance regulations, provide enhanced privacy through
            advanced cryptographic techniques like Zero-Knowledge Proofs, and
            offer a more user-friendly experience with features like Account
            Abstraction
          </p>
          <p className={styles.contentPara}>
            Moreover, Layer 3 solutions can facilitate seamless interoperability
            with other blockchains and traditional financial systems. This is
            crucial for RWAs as it allows for the easy transfer and management
            of assets across different environments, making them more liquid and
            accessible to a wider range of investors
          </p>
          <p className={styles.contentPara}>
            Additionally, Layer 3 platforms can significantly reduce transaction
            costs and improve transaction speeds, which are essential factors
            for startups and institutions dealing with RWAs. By minimizing fees
            and ensuring efficient processing, Layer 3 chains like Complere make
            it more feasible for businesses to adopt blockchain technology for
            their operations
          </p>
          <h2 className={styles.bold} id="security">
            Security of Ethereum
          </h2>
          <div className={styles.img_blog}>
            <img alt="" className={styles.logoImg} src="/security.png" />
          </div>
          <p className={styles.contentPara}>
            Ethereum, functioning as a public blockchain, embodies key
            principles of decentralization, transparency, and the facilitation
            of decentralized applications (dApps). Its inclusive nature welcomes
            participation from anyone interested, empowering individuals to
            validate transactions and uphold a synchronized copy of the
            blockchain. This decentralized framework not only fosters openness
            but also holds the potential for significant scalability
            improvements.
          </p>
          <p className={styles.contentPara}>
            In stark contrast, private blockchains operate within confined
            parameters, restricted to specific participant groups necessitating
            permissions or invitations for access. Primarily utilized within
            organizational settings for internal operations, private blockchains
            prioritize heightened privacy and control. However, their inherent
            limitations expose them to greater susceptibilities such as security
            breaches and data manipulation, presenting easier targets for
            malicious actors to disrupt the network. Moreover, the default
            privacy assurance in private blockchains remains ambiguous, with a
            clear distinction between permissioned networks and private
            transaction managers.
          </p>
          <p className={styles.contentPara}>
            The increasing demand for tokenized assets underscores the
            imperative need for a secure and dependable platform to safeguard
            the integrity and credibility of digital assets. Ethereum's
            established security infrastructure positions it as a superior
            alternative to private blockchains, which have demonstrated
            vulnerabilities ranging from security breaches to transparency
            deficits and sluggish response times due to their centralized nature
            and limited security protocols. As Real-World Assets (RWAs) strive
            for widespread acceptance and trust, harnessing the robust security
            features inherent in public blockchains like Ethereum becomes
            paramount. Ethereum's adoption of the Proof of Stake (PoS) consensus
            mechanism further bolsters its security profile, solidifying its
            status as a dependable foundation for Layer 3 solutions such as
            Complere.
          </p>
          <h2 className={styles.bold} id="lindy">
            Lindy Effect of Ethereum
          </h2>
          <p className={styles.contentPara}>
            Ethereum stands out with a formidable network effect, showcasing
            unparalleled adoption and utilization, particularly within the
            decentralized finance (DeFi) realm. As of 2024, Ethereum commands a
            commanding lead, securing 72% of DeFi's total value locked (TVL) and
            hosting 56% of stablecoins on-chain. This supremacy primarily stems
            from Ethereum's robust smart contract execution capabilities.
            Although Bitcoin does support smart contracts, they pale in
            comparison to Ethereum's in terms of flexibility and
            comprehensiveness, empowering developers to craft intricate
            decentralized applications (DApps) and intricate financial services.
          </p>
          <p className={styles.contentPara}>
            Addressing Ethereum's scalability and cost challenges at Layer 1,
            emerging Layer 2 solutions have efficaciously boosted transaction
            throughput and slashed fees. Consequently, Ethereum emerges as a
            more enticing option for institutional builders compared to
            alternative Layer 1 networks. Its unwavering security and
            extensively tested infrastructure confer it an advantage over the
            majority of Layer 1 altcoins, resonating with institutional
            builders' priority for a secure and dependable blockchain. The
            diverse assortment of DeFi protocols thriving on Ethereum, ranging
            from lending platforms to decentralized exchanges and yield farming
            ventures, fosters a dynamic ecosystem ripe for innovation and
            collaboration among institutional builders.
          </p>
          <p className={styles.contentPara}>
            With over a decade of existence, Ethereum has navigated through
            adversities, undergone upgrades, and tackled scalability hurdles,
            reaffirming its sustainability. Its governance frameworks, consensus
            mechanisms, and network security ecosystem render it an alluring
            option for institutions. Furthermore, its smart contract prowess,
            myriad applications, and developer-friendly ambiance position it as
            the preferred blockchain for institutional endeavors. The
            discernment of Ethereum's value by institutions solidifies its
            standing as a compelling choice in the blockchain arena.
          </p>
          <h2 className={styles.bold} id="use">
            Use Case Specific Layer 3 Solutions
          </h2>
          <p className={styles.contentPara}>
            Complere, as a Layer 3 solution, operates on top of Layer 2
            solutions, catering to specific use cases such as blockchain gaming,
            DeFi, and DApps. This tailored approach allows for higher
            transaction throughput and faster processing speeds, enhancing user
            experience and platform performance.
          </p>
          <div className={styles.img_blog}>
            <img alt="" className={styles.logoImg} src="/regulation.png" />
          </div>
          <h4 className={styles.bold}>
            Regulation Compliance- Ensuring legal assurance for users and
            developers
          </h4>
          <p className={styles.contentPara}>
            Regulation compliance is crucial in real-world asset (RWA)
            tokenization to ensure that all transactions adhere to legal
            standards and frameworks. This provides legal assurance for both
            users and developers, fostering trust and confidence in the
            platform. By complying with regulatory requirements, Complere Chain
            mitigates legal risks, promotes transparency, and enhances its
            credibility with institutional investors and regulatory bodies.
          </p>
          <h4 className={styles.bold}>
            Account Abstraction - Simplifying blockchain interactions for
            mainstream adoption
          </h4>
          <p className={styles.contentPara}>
            Account abstraction simplifies the interaction between users and the
            blockchain, making it more accessible to a broader audience. This
            feature abstracts away complex blockchain processes, allowing users
            to engage with the platform without needing in-depth technical
            knowledge. For RWA tokenized assets, this simplification is
            essential for attracting mainstream users and facilitating wider
            adoption, as it lowers the barrier to entry and enhances the overall
            user experience.
          </p>
          <h4 className={styles.bold}>
            Privacy of Transactions and Wallets- Protects sensitive transaction
            details and wallet identities
          </h4>
          <p className={styles.contentPara}>
            Privacy is a paramount concern in financial transactions, especially
            when dealing with RWAs. Complere Chain’s emphasis on transaction and
            wallet privacy ensures that sensitive details remain confidential.
            This protection is vital for maintaining the anonymity of users and
            securing proprietary financial information, thereby preventing
            unauthorized access and potential fraud. Enhanced privacy measures
            build user trust and encourage participation in the tokenized asset
            ecosystem.
          </p>
          <h4 className={styles.bold}>
            Zero Knowledge Proof- Enhancing privacy and security without
            revealing underlying data
          </h4>
          <p className={styles.contentPara}>
            Zero knowledge proof (ZKP) technology allows transactions to be
            verified without disclosing the underlying data. This significantly
            enhances privacy and security on the Complere Chain. In the context
            of RWA tokenization, ZKPs enable secure and private transactions,
            ensuring that asset ownership and transaction details are protected.
            This technology is crucial for maintaining confidentiality while
            still ensuring the integrity and validity of transactions.
          </p>
          <h4 className={styles.bold}>
            Self-Custodial and Custodial Solutions- Offers flexible custody
            options to suit diverse user needs
          </h4>
          <p className={styles.contentPara}>
            Complere Chain provides both self-custodial and custodial solutions,
            catering to the diverse needs of its users. Self-custodial solutions
            empower users with full control over their assets, enhancing
            security and personal responsibility. Custodial solutions, on the
            other hand, offer a managed approach where a trusted third party
            holds and secures assets. This flexibility is essential in RWA
            tokenization, as it accommodates varying levels of user expertise
            and preferences for asset management.
          </p>
          <h4 className={styles.bold}>
            Gasless Transactions- Eliminates transaction fees, encouraging more
            frequent platform use
          </h4>
          <p className={styles.contentPara}>
            Gasless transactions remove the need for users to pay transaction
            fees, making the platform more accessible and encouraging more
            frequent use. This feature is particularly important in the RWA
            space, where high transaction fees can be a barrier to adoption. By
            eliminating these fees, Complere Chain lowers the cost of
            participation, promoting a more active and engaged user base. This
            not only enhances user experience but also increases the liquidity
            and efficiency of the tokenized asset market.
          </p>
          <p className={styles.contentPara}>
            The tokenization of RWAs is not just about technological
            advancements; it's also about creating a framework that is compliant
            with regulatory standards and attractive to institutional investors.
            Complere serves as a gateway for institutional adoption of
            tokenization and cryptocurrency through RWAs. By providing a secure,
            scalable, and regulation-compliant infrastructure, Complere is
            paving the way for major financial institutions to participate in
            the digital asset economy.
          </p>
          <h2 className={styles.bold} id="eco">
            Complere Ecosystem
          </h2>
          <p className={styles.contentPara}>
            Complere, operating as a Layer 3 blockchain integrated with the BASE
            network, endeavors to forge a seamless connection between Web3 and
            traditional finance (TradFi) realms within a secure and legally
            compliant framework. Its primary objective lies in facilitating the
            efficient tokenization of physical assets such as real estate, art,
            and commodities, thereby enabling fractional ownership and
            frictionless asset transfer across the blockchain. This
            groundbreaking approach empowers traders and investors to engage in
            global token trading, substantially mitigating the friction and
            delays typically associated with traditional commodity markets.
          </p>
          <p className={styles.contentPara}>
            Moreover, Complere extends its support to decentralized applications
            (DApps), furnishing a secure and scalable platform for their
            operations. Leveraging smart contracts, Complere facilitates
            decentralized lending, borrowing, yield farming, and automated
            market-making (AMM) protocols, effectively eliminating the need for
            intermediaries and enhancing accessibility to DeFi services.
          </p>
          <p className={styles.contentPara}>
            Distinguishing itself with a Layer 3 architecture, Complere provides
            developers with a versatile infrastructure to craft Decentralized
            Private Information Network (DePIN) solutions. These networks
            bolster privacy, facilitate secure data sharing, and enable
            encrypted communication within the ecosystem. Complere also
            accommodates money market protocols, empowering users to lend,
            borrow, earn interest on digital assets, and maintain stablecoin
            pegs, all while upholding transparency, security, and efficient
            capital allocation. Additionally, it streamlines the tokenization of
            properties, enabling investors to acquire fractional ownership in
            real estate assets, thereby lowering barriers to entry. Smart
            contracts are entrusted with tasks such as rent collection, property
            management, and the maintenance of transparent ownership records.
          </p>
          <p className={styles.contentPara}>
            By leveraging existing Layer 2 solutions like the BASE network,
            Complere crafts a highly adaptable Layer 3 framework. It capitalizes
            on the security features of the BASE network while affording
            developers the flexibility to tailor their applications to suit
            specific requirements. This approach facilitates seamless
            interaction with other blockchains, ensuring fluid cross-chain
            transactions and data sharing. With its capacity for high
            throughput, Complere emerges as a well-suited platform for managing
            a substantial volume of transactions spanning diverse use cases.
          </p>
          <h2 className={styles.bold} id="road">
            Complere Roadmap
          </h2>
          <p className={styles.contentPara}>
            We are thrilled to announce the launch of our Testnet in June 2024.
            This is a prime opportunity for developers and early adopters to
            explore the platform’s functionalities, contribute to its
            improvement, and build pioneering applications that leverage our
            advanced blockchain technology. We are excited to invite
            forward-thinking startups and esteemed institutions to join us in
            shaping the future of finance and technology on the Complere Chain
          </p>
          <p className={styles.contentPara}>
            Complere is a cutting-edge Layer 3 blockchain built on the secure
            and scalable BASE network. Our mission is to create a seamless
            bridge between Web3 and traditional finance (TradFi) within a
            compliant and robust environment. By enabling the efficient
            tokenization of physical assets and offering a platform for
            decentralized applications (Dapps), Complere is poised to
            revolutionize how we interact with financial and digital assets.
          </p>
          <h2 className={styles.bold} id="build">
            Why Build on Complere?
          </h2>
          <p className={styles.contentPara}>
            Launching Real World Assets (RWAs) on the blockchain can be a
            lengthy process, often taking anywhere from 6 months to 3 years due
            to regulatory compliance and technical hurdles.
          </p>
          <p className={styles.contentPara}>
            The RWA market is notably scattered, with liquidity thinly
            distributed and a subpar user experience for both developers and
            end-users. Despite these challenges, the RWA sector presents a
            significant opportunity within the web3 space, yet it lacks a
            centralized platform
          </p>
          <p className={styles.contentPara}>
            Complere emerges as the Layer 3 platform designed for the
            crypto-savvy community, aiming to become the go-to platform for RWA
            tokenization. It streamlines the complexity of bringing RWAs onto
            the blockchain by offering a comprehensive tokenization toolkit.
            This toolkit facilitates the entire process, from establishing legal
            entities to managing custody and wallets, as well as simplifying
            paperwork, on/off-ramp processes, deployment, and a one-time Know
            Your Customer (KYC)/Anti-Money Laundering (AML) procedure.
          </p>
          <p className={styles.contentPara}>
            By enabling the issuance of RWA products on Complere, investors can
            retain their capital within the blockchain ecosystem, earning
            returns on assets that are not correlated with the market, thereby
            reducing portfolio volatility and broadening asset diversification.
          </p>
          <p className={styles.contentPara}>
            Building a community around RWA protocols can be challenging, as the
            products may appear conventional. However, Complere adopts a
            crypto-native strategy to overcome this, positioning itself as a hub
            for these projects. By integrating with Layer 3, projects can
            effortlessly connect to a broader ecosystem.
          </p>

          <ul>
            <li style={{ fontWeight: 600, fontFamily: "inter" }}>
              {" "}
              Innovative Infrastructure:
              <span
                style={{
                  fontWeight: 500,
                  fontFamily: "inter",
                  letterSpacing: "0.2px",
                }}
              >
                {" "}
                Leverage Complere’s customizable Layer 3 architecture to develop
                and deploy advanced blockchain solutions.
              </span>
            </li>
            <li
              style={{
                fontWeight: 600,
                fontFamily: "inter",
                letterSpacing: "0.2px",
              }}
            >
              Tokenization Capabilities:
              <span
                style={{
                  fontWeight: 500,
                  fontFamily: "inter",
                  letterSpacing: "0.2px",
                }}
              >
                {" "}
                Easily tokenize physical assets such as real estate, art, and
                commodities, enabling fractional ownership and global trading.
              </span>
            </li>
            <li
              style={{
                fontWeight: 600,
                fontFamily: "inter",
                letterSpacing: "0.2px",
              }}
            >
              DeFi Opportunities:
              <span
                style={{
                  fontWeight: 500,
                  fontFamily: "inter",
                  letterSpacing: "0.2px",
                }}
              >
                {" "}
                Utilize our secure smart contracts to create decentralized
                lending, borrowing, yield farming, and automated market-making
                (AMM) protocols.
              </span>
            </li>
            <li
              style={{
                fontWeight: 600,
                fontFamily: "inter",
                letterSpacing: "0.2px",
              }}
            >
              Cross-Chain Interoperability:
              <span
                style={{
                  fontWeight: 500,
                  fontFamily: "inter",
                  letterSpacing: "0.2px",
                }}
              >
                {" "}
                Benefit from seamless cross-chain transactions and data sharing,
                ensuring high throughput and robust performance.
              </span>
            </li>
            <li
              style={{
                fontWeight: 600,
                fontFamily: "inter",
                letterSpacing: "0.2px",
              }}
            >
              Supportive Ecosystem:
              <span
                style={{
                  fontWeight: 500,
                  fontFamily: "inter",
                  letterSpacing: "0.2px",
                }}
              >
                {" "}
                Join a vibrant community of developers, innovators, and industry
                leaders, and gain access to resources, documentation, and
                support.
              </span>
            </li>
          </ul>
          <h2 className={styles.bold} id="involved">
            Get Involved
          </h2>
          <p style={{ textAlign: "left", fontFamily: "inter" }}>
            We invite startups and institutions to:
          </p>
          <ul>
            <li
              style={{
                fontWeight: 600,
                fontFamily: "inter",
                letterSpacing: "0.2px",
              }}
            >
              Develop and Test:
              <span
                style={{
                  fontWeight: 500,
                  fontFamily: "inter",
                  letterSpacing: "0.2px",
                }}
              >
                {" "}
                Be among the first to develop and test your applications on our
                Testnet.
              </span>
            </li>
            <li style={{ fontWeight: 600, fontFamily: "inter" }}>
              Collaborate:
              <span
                style={{
                  fontWeight: 500,
                  fontFamily: "inter",
                  letterSpacing: "0.2px",
                }}
              >
                {" "}
                Partner with us to explore innovative use cases and enhance the
                Complere ecosystem.
              </span>
            </li>

            <li style={{ fontWeight: 600, fontFamily: "inter" }}>
              Grow Together:
              <span
                style={{
                  fontWeight: 500,
                  fontFamily: "inter",
                  letterSpacing: "0.2px",
                }}
              >
                {" "}
                Participate in our community events, hackathons, and workshops
                to learn, share, and grow together.
              </span>
            </li>
          </ul>
          <p className={styles.contentPara}>
            Complere is not just a blockchain platform; it's a gateway to a new
            era of decentralized finance and asset management. Together, we can
            push the boundaries of what’s possible and drive the next wave of
            technological and financial innovation.
          </p>
          <p className={styles.contentPara}>
            Join us on this exciting journey. Let’s build the future on
            Complere.
          </p>
        </div>
      </div>

      {/* desktop footer */}
      <div className={styles.footerContent}>
        <div className={styles.footerEle}>
          <div className={styles.footerLeft}>
            <div className={styles.footerLHalf}>
              <Link to="/">
                <img alt="" src="/Complere-white.svg" />
              </Link>
              <div className={styles.wwwcomplerexyz}>
                The Compliant RWA Layer3 Chain
              </div>
            </div>
            <div className={styles.footerLHalf2}>
              <Link
                to="/about"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <div className={styles.footerFeature}>About Us</div>
              </Link>
              <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
                <div className={styles.footerFeature}>Features</div>
              </Link>
            </div>
            <div className={styles.footerLHalf2}>
              <div className={styles.footerFeature}>
                <Link
                  target="_blank"
                  to="https://complere.gitbook.io/complere-chain-core-docs"
                  style={{ textDecoration: "none", color: "#FFFF" }}
                >
                  Build
                </Link>
              </div>
              <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
                <div className={styles.footerFeature}>Ecosystem</div>
              </Link>
            </div>
          </div>
          <div className={styles.footerRight}>
            <div>
              <a target="_blank" href="https://discord.gg/jjfmXJNhzS">
                <img
                  className={styles.discordIcon}
                  alt=""
                  src="/discord-svgrepo-com.svg"
                />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                href="https://www.instagram.com/complerechain/"
              >
                <img
                  className={styles.discordIcon}
                  alt=""
                  src="/instagram-img.svg"
                />
              </a>
            </div>
            <div>
              <a target="_blank" href="https://www.facebook.com/complere/">
                <img
                  className={styles.discordIcon}
                  alt=""
                  src="/facebook-img.svg"
                />
              </a>
            </div>
            <div>
              <a target="_blank" href="https://twitter.com/complerechain/">
                <img
                  className={styles.discordIcon}
                  alt=""
                  src="/icons8twitterx-1.svg"
                />
              </a>
            </div>
            <div>
              <a target="_blank" href="https://t.me/complerechain">
                <img
                  className={styles.discordIcon}
                  alt=""
                  src="/telegram-svgrepo-com.svg"
                />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/complere-chain/"
              >
                <img
                  className={styles.discordIcon}
                  alt=""
                  src="/linked-in1.svg"
                />
              </a>
            </div>
          </div>
        </div>
        <hr className="solid" style={{ width: "90%", marginTop: "-40px" }} />
        <div className={styles.footerLow}>
          <div className={styles.footerText1}>
            © 2024 Complere Chain | All rights reserved
          </div>
          <div className={styles.footerLowRight}>
            <Link
              to="/endofuser"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className={styles.footerText2}>
                End User Licence Agreement
              </div>
            </Link>
            <Link
              to="/privacy"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className={styles.footerText}>Privacy Policy</div>
            </Link>
            <div
              onClick={() => (window.location = "mailto:hello@complere.xyz")}
              className={styles.footerText}
            >
              Contact Us
            </div>
          </div>
        </div>
      </div>

      {/* mobile footer */}
      <div className={styles.mobFooterContent}>
        <div className={styles.mobHeader}>
          <Link to="/">
            <img alt="" src="/Complere-white.svg" />
          </Link>
        </div>
        <div className={styles.wwwcomplerexyz}>
          The Compliant RWA Layer3 Chain
        </div>

        <div className={styles.mobele}>
          <div className={styles.mobItem}>
            <Link to="/about" style={{ textDecoration: "none", color: "#fff" }}>
              <div className={styles.mobsubItem}>About Us</div>
            </Link>
            <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
              <div className={styles.mobsubItem}>Features</div>
            </Link>
          </div>
          <div className={styles.mobItem}>
            <div className={styles.mobsubItem}>
              <Link
                target="_blank"
                to="https://complere.gitbook.io/complere-chain-core-docs"
                style={{ textDecoration: "none", color: "#FFFF" }}
              >
                Build
              </Link>
            </div>
            <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
              <div className={styles.mobsubItem}>Ecosystem</div>
            </Link>
          </div>
        </div>
        <div className={styles.moblogo}>
          <div>
            <a target="_blank" href="https://www.facebook.com/complere/">
              <img
                className={styles.discordIconmob}
                alt=""
                src="/facebook-img.svg"
              />
            </a>
          </div>
          <div>
            <a target="_blank" href="https://www.instagram.com/complerechain/">
              <img
                className={styles.discordIconmob}
                alt=""
                src="/instagram-img.svg"
              />
            </a>
          </div>

          <div>
            <a target="_blank" href="https://twitter.com/complerechain/">
              <img
                className={styles.discordIconmob}
                alt=""
                src="/icons8twitterx-1.svg"
              />
            </a>
          </div>
          <div>
            <a target="_blank" href="https://t.me/complerechain">
              <img
                className={styles.discordIconmob}
                alt=""
                src="/telegram-svgrepo-com.svg"
              />
            </a>
          </div>
          <div>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/complere-chain/"
            >
              <img
                className={styles.discordIconmob}
                alt=""
                src="/linked-in1.svg"
              />
            </a>
          </div>
          <div>
            <a target="_blank" href="https://discord.gg/jjfmXJNhzS">
              <img
                className={styles.discordIconmob}
                alt=""
                src="/discord-svgrepo-com.svg"
              />
            </a>
          </div>
        </div>
        <div className={styles.moblowfoot}>
          © 2024 Complere Chain | All rights reserved
        </div>
        <hr className="solid" style={{ width: "90%", marginTop: "10px" }} />
        <Link to="/endofuser" style={{ textDecoration: "none", color: "#fff" }}>
          <div className={styles.mobbottom}>End User Licence Agreement </div>
        </Link>
        <div className={styles.mobfootbottom}>
          <Link to="/privacy" style={{ textDecoration: "none", color: "#fff" }}>
            <div className={styles.mobbottom}>Privacy Policy</div>
          </Link>
          <div
            onClick={() => (window.location = "mailto:hello@complere.xyz")}
            className={styles.mobbottom}
          >
            Contact Us
          </div>
        </div>
      </div>
    </div>
  );
};
export default Blog1;
