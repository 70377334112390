import styles from "./Blog2.module.css";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
// import FrameComponent from "../components/FrameComponent";
import { useState } from "react";
import { Link, useLocation } from "react-router-dom";
import { Link as ScrollLink } from "react-scroll";
import { ToastContainer, toast } from "react-toastify";
// import Linkedin from "../components/Linkedin";
// import Instagram from "../components/Instagram";

const Blog2 = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [learnButton, setLearnButton] = useState(false);
  const [learnMob, setLearnMob] = useState(false);

  const location = useLocation();
  const { hash, pathname, search } = location;
  console.log(pathname, "lsoslso");

  const learnDropdown = () => {
    setLearnButton(true);
  };
  const learnDropdown2 = () => {
    setLearnButton(false);
  };
  const toggleNavbar = () => {
    console.log("tooclcl");
    setIsOpen(!isOpen);
    if (learnMob) {
      setLearnMob(false);
    }
  };
  const notify = () => {
    toast.success("Coming soon!");
  };
  const mobLearnMenu = () => {
    setLearnMob(!learnMob);
    setIsOpen(false);
  };
  console.log(learnButton, "learnButton", isOpen, "lmon", learnMob);

  return (
    <div className={styles.desktop1}>
      {/* mobile nav */}
      <div
        style={{
          display: "flex",
          width: "100%",
          justifyContent: "space-between",
        }}
      >
        <div>
          <Link to="/">
            {" "}
            <img
              className={styles.mobnavlogo}
              alt=""
              src="/complere_logo.svg"
            />
          </Link>
        </div>
        <div onClick={toggleNavbar} className={styles.ham}>
          <img
            className={styles.hamburgerMenuSvgrepoCom1Icon}
            alt=""
            src="/hamburger-menu.png"
          />
        </div>
      </div>
      <ToastContainer
        position="top-right"
        autoClose={5000}
        hideProgressBar={false}
        newestOnTop={false}
        closeOnClick
        rtl={false}
        pauseOnFocusLoss
        draggable
      />
      {isOpen && (
        <div className={styles.hamMenu}>
          <div onClick={mobLearnMenu} className={styles.menItem}>
            Learn
            <hr className="solid" />
          </div>
          <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#FFFF" }}>
          <div className={styles.menItem}>
            Build
            <hr className="solid" />
          </div>
          </Link>
          <div onClick={notify} className={styles.menItem}>
            Explore
            <hr className="solid" />
          </div>
          <div className={styles.menItem}>
            <Link
              to="/"
              style={{
                textDecoration: "none",
                color: "white",
              }}
            >
              Ecosystem
            </Link>
            <hr className="solid" />
          </div>
          <Link target="_blank" to='https://complere-bridge-test-deploy.vercel.app/?destinationChain=complere-chain&sourceChain=base-sepolia' style={{ textDecoration: "none",color:"#C71585" }}>
          <div className={styles.menItem}>
            Bridge
            {/* <hr className="solid" /> */}
          </div>
          </Link>
        </div>
      )}
      {learnMob && (
        <div className={styles.moblearnMenu}>
          <Link to="/about" onClick={mobLearnMenu}>
            {" "}
            <div style={{ marginTop: "-5px" }} className={styles.moblearnItem}>
              About us
              <hr style={{ marginTop: "20px" }} className="solid" />
            </div>
          </Link>
          <Link
            to="/blogpage"
            style={{ textDecoration: "none" }}
            onClick={mobLearnMenu}
          >
            <div className={styles.moblearnItem}>Blog</div>
          </Link>
        </div>
      )}
      {/* desktpo nav */}
      <div style={{ display: "flex", justifyContent: "center", width: "100%" }}>
        <div className={styles.nav}>
          <div className={styles.navtitle}>
            <Link to="/">
              <img alt="" src="/complere_logo.svg" />
            </Link>
          </div>
          <div className={styles.navSub}>
            <div className={styles.navText}>
              <Link
                to="/"
                style={{
                  textDecoration: "none",
                  color: "var(--color-darkslateblue)",
                }}
              >
                Ecosystem
              </Link>
            </div>
            <div
              onMouseEnter={learnDropdown}
              onMouseLeave={learnDropdown2}
              className={styles.navText3}
            >
              Learn
              <img
                className={styles.dropdownArrowSvgrepoCom1Icn}
                alt=""
                src="/dropdownarrowsvgrepocom-1.svg"
              />
              {learnButton && (
                <div className={styles.learnMenu}>
                  <Link
                    to="/about"
                    style={{ textDecoration: "none" }}
                    onClick={learnDropdown2}
                  >
                    <div className={styles.learnItem}>
                      About us
                      <hr className="solid" />
                    </div>
                  </Link>
                  <Link
                    to="/blogpage"
                    style={{ textDecoration: "none" }}
                    onClick={learnDropdown2}
                  >
                    <div className={styles.learnItem2}>Blog
                    <hr className="solid" />
                    </div>
                  </Link>
                  <Link to="/faq" style={{ textDecoration: "none" }} >
              <div className={styles.learnItem2}>FAQs</div>
              </Link>
                </div>
              )}
            </div>
        
            <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#FFFF" }}>

              <div className={styles.navText3}>
                Build
                <img
                  className={styles.dropdownArrowSvgrepoCom1Icn}
                  alt=""
                  src="/dropdownarrowsvgrepocom-1.svg"
                />
              </div>
              </Link>
            <Tooltip
              style={{ marginLeft: 0 }}
              interactive
              trigger="click"
              touchHold={true}
              stickyDuration={200}
              html={
                <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
                  <strong style={{ fontFamily: "Inter" }}>
                    Coming soon...
                  </strong>
                </div>
              }
            >
              <div className={styles.navText3}>
                Explore
                <img
                  className={styles.dropdownArrowSvgrepoCom1Icn}
                  alt=""
                  src="/dropdownarrowsvgrepocom-1.svg"
                />
              </div>
            </Tooltip>
          
            <Link target="_blank" to='https://complere-bridge-test-deploy.vercel.app/?destinationChain=complere-chain&sourceChain=base-sepolia' style={{ textDecoration: "none",color:"#C71585" }}>
              <div className={styles.navText2}>
                Bridge
                <img
                  className={styles.arrowUpRightFromSquareSvgIcn}
                  alt=""
                  src="/arrowuprightfromsquaresvgrepocom-1.svg"
                />
              </div>
              </Link>
          </div>
        </div>
      </div>

      <div className={styles.contentText}>
        <div className={styles.content} id="intro">
        <p style={{color:'white',fontFamily: "inter",fontSize:'16px'}}>Learn &gt;<Link to='/blogpage' style={{color:'white',textDecoration: "none",fontFamily: "inter",fontSize:'16px'}}> Blogs</Link> &gt; <Link to="/blog/navigating-the-future" style={{color:'white',textDecoration: "none",fontFamily: "inter",fontSize:'16px'}}>Navigating the Future</Link></p>
          <h2
            className={styles.introBlog}
            // style={{
            //   textAlign: "left",
            //   fontFamily: "inter",
            //   fontWeight: 400,
            //   fontSize: "42px",
            //   color: "#ffff",
            // }}
          >
            Navigating the Future: The Impact of Asset Tokenization on Global
            Markets
          </h2>
          <p className={styles.introPara}>
            Published on : 06 July 2024&nbsp;&nbsp;&nbsp;|&nbsp;
            <span>&nbsp; 10 min read</span>
          </p>
        </div>
        {/* con */}
      </div>

      <div className={styles.paradiv}>
        <div className={styles.contentNavigation}>
          <div className={styles.subcontentNavigation}>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="intro"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                Intro
              </ScrollLink>
            </div>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="history"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                What is Asset Tokenisation ?
              </ScrollLink>
            </div>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="market"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                {" "}
                The Benefits of Asset Tokenisation
              </ScrollLink>
            </div>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="complere"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                The Impact on Global Markets
              </ScrollLink>
            </div>
            <div className={styles.contentNavigationText}>
              <ScrollLink
                to="key"
                spy={true}
                smooth={true}
                offset={-10}
                duration={500}
                onClick={toggleNavbar}
              >
                The Road Ahead
              </ScrollLink>
            </div>
          </div>
        </div>
        <div className={styles.allPara}>
          <p className={styles.contentPara} id="intro">
            In the rapidly evolving landscape of finance and technology, asset
            tokenization is emerging as a transformative force poised to reshape
            global markets. As the world increasingly embraces digital
            innovation, understanding the profound impact of asset tokenization
            becomes crucial for investors, financial institutions, and
            policymakers alike.
          </p>
   
          <h2 className={styles.bold} id="history">
            What is Asset Tokenization?{" "}
          </h2>
          <p className={styles.contentPara}>
            Asset tokenization involves converting physical and intangible
            assets into digital tokens that exist on a blockchain. This process
            allows a wide range of assets—including real estate, art, stocks,
            bonds, and commodities—to be represented digitally. Each token
            represents a share or portion of the underlying asset, making
            ownership more accessible, transparent, and divisible.
          </p>
         
          <h2 className={styles.bold} id="market">
          The Benefits of Asset Tokenization
          </h2>
          <p className={styles.contentPara}>
          1. Increased Liquidity: Traditionally illiquid assets, such as real estate or fine art, can be made more liquid through tokenization. By breaking down these assets into smaller, tradeable tokens, investors can buy and sell fractional ownership, significantly enhancing market liquidity.
          </p>
          <p className={styles.contentPara}>
          2. Enhanced Transparency: Blockchain technology ensures that all transactions are recorded on a public ledger, providing a transparent and immutable record of ownership. This transparency reduces the risk of fraud and enhances trust among market participants.
          </p>
          <p className={styles.contentPara}>
          3. Accessibility and Inclusivity: Asset tokenization democratizes investment opportunities by lowering the entry barriers. Smaller investors can access high-value assets that were previously out of reach, fostering greater financial inclusivity.
          </p>
          <p className={styles.contentPara}>
          4. Efficiency and Cost Reduction: The traditional processes involved in asset trading, such as documentation, verification, and settlement, can be time-consuming and costly. Tokenization streamlines these processes, reducing administrative overheads and transaction costs.          </p>
          <h2 className={styles.bold} id="complere">
          The Impact on Global Markets
          </h2>
          <p className={styles.contentPara}>
          The rise of asset tokenization is poised to bring several significant changes to global markets:
          </p>
          
          <p className={styles.contentPara}>
          1. Diversification of Investment Portfolios: Investors can diversify their portfolios by including tokenized assets, which were previously difficult to access. This diversification can help mitigate risks and enhance returns.
          </p>
          <p className={styles.contentPara}>
          2. Globalization of Assets: Tokenized assets can be traded on global digital exchanges, breaking down geographical barriers and creating a more interconnected global market. This globalization facilitates cross-border investments and promotes economic integration.
          </p>
          <p className={styles.contentPara}>
          3. Innovation in Financial Products: The flexibility of tokenized assets allows for the creation of innovative financial products and services. For example, tokenized real estate investment trusts (REITs) or decentralized finance (DeFi) platforms can provide new avenues for investment and fundraising.
          </p>
          <p className={styles.contentPara}>
          4. Regulatory Evolution: As asset tokenization gains traction, regulatory frameworks will need to evolve to address new challenges and opportunities. Regulators will play a crucial role in ensuring market integrity, protecting investors, and fostering innovation.          </p>
          <p className={styles.contentPara}>
          5. Challenges and Risks: Despite its potential, asset tokenization also presents challenges. Issues related to regulatory compliance, cybersecurity, market volatility, and the interoperability of blockchain networks must be carefully navigated to ensure sustainable growth.          </p>
          <h2 className={styles.bold} id="key">
          The Road Ahead
          </h2>
          <p className={styles.contentPara}>
          The future of asset tokenization is promising, with the potential to revolutionize how assets are owned, traded, and managed. However, realizing this potential requires collaboration among stakeholders, including governments, financial institutions, technology providers, and investors.
          </p>
          <h4 className={styles.bold}>To navigate the future of asset tokenization, stakeholders must:</h4>
          <p className={styles.contentPara}>
          1. Embrace Innovation: Continually explore and adopt innovative technologies that enhance the tokenization process and address emerging challenges.
          </p>
          <p className={styles.contentPara}>
          2. Foster Collaboration: Encourage collaboration across industries and borders to create a cohesive and resilient global tokenization ecosystem.
          </p>
          <p className={styles.contentPara}>
          3. Prioritize Education: Invest in education and awareness initiatives to help market participants understand the benefits and risks of asset tokenization.
          </p>
          <p className={styles.contentPara}>
          4. Develop Robust Regulations: Work towards developing comprehensive regulatory frameworks that balance innovation with investor protection and market stability.
          </p>
          <p className={styles.contentPara}>
          Asset tokenization represents a paradigm shift in the financial world, offering unprecedented opportunities for market participants. By leveraging blockchain technology, tokenization enhances liquidity, transparency, and accessibility, paving the way for a more inclusive and efficient global market. As we navigate the future, embracing the transformative potential of asset tokenization will be key to unlocking new frontiers in finance and investment.
          </p>
          
      </div>
      </div>

      {/* desktop footer */}
      <div className={styles.footerContent}>
        <div className={styles.footerEle}>
          <div className={styles.footerLeft}>
            <div className={styles.footerLHalf}>
              <Link to="/">
                <img alt="" src="/Complere-white.svg" />
              </Link>
              <div className={styles.wwwcomplerexyz}>
                The Compliant RWA Layer3 Chain
              </div>
            </div>
            <div className={styles.footerLHalf2}>
              <Link
                to="/about"
                style={{ textDecoration: "none", color: "#fff" }}
              >
                <div className={styles.footerFeature}>About Us</div>
              </Link>
              <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
                <div className={styles.footerFeature}>Features</div>
              </Link>
            </div>
            <div className={styles.footerLHalf2}>
              <div className={styles.footerFeature}>
              
                                <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#FFFF" }}>

                  Build
                  </Link>
              </div>
              <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
                <div className={styles.footerFeature}>Ecosystem</div>
              </Link>
            </div>
          </div>
          <div className={styles.footerRight}>
            <div>
              <a target="_blank" href="https://discord.gg/jjfmXJNhzS">
                <img
                  className={styles.discordIcon}
                  alt=""
                  src="/discord-svgrepo-com.svg"
                />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                href="https://www.instagram.com/complerechain/"
              >
                <img
                  className={styles.discordIcon}
                  alt=""
                  src="/instagram-img.svg"
                />
              </a>
            </div>
            <div>
              <a target="_blank" href="https://www.facebook.com/complere/">
                <img
                  className={styles.discordIcon}
                  alt=""
                  src="/facebook-img.svg"
                />
              </a>
            </div>
            <div>
              <a target="_blank" href="https://twitter.com/complerechain/">
                <img
                  className={styles.discordIcon}
                  alt=""
                  src="/icons8twitterx-1.svg"
                />
              </a>
            </div>
            <div>
              <a target="_blank" href="https://t.me/complerechain">
                <img
                  className={styles.discordIcon}
                  alt=""
                  src="/telegram-svgrepo-com.svg"
                />
              </a>
            </div>
            <div>
              <a
                target="_blank"
                href="https://www.linkedin.com/company/complere-chain/"
              >
                <img
                  className={styles.discordIcon}
                  alt=""
                  src="/linked-in1.svg"
                />
              </a>
            </div>
          </div>
        </div>
        <hr className="solid" style={{ width: "90%", marginTop: "-40px" }} />
        <div className={styles.footerLow}>
          <div className={styles.footerText1}>
            © 2024 Complere Chain | All rights reserved
          </div>
          <div className={styles.footerLowRight}>
            <Link
              to="/endofuser"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className={styles.footerText2}>
                End User Licence Agreement
              </div>
            </Link>
            <Link
              to="/privacy"
              style={{ textDecoration: "none", color: "#fff" }}
            >
              <div className={styles.footerText}>Privacy Policy</div>
            </Link>
            <div
              onClick={() => (window.location = "mailto:hello@complere.xyz")}
              className={styles.footerText}
            >
              Contact Us
            </div>
          </div>
        </div>
      </div>

      {/* mobile footer */}
      <div className={styles.mobFooterContent}>
        <div className={styles.mobHeader}>
          <Link to="/">
            <img alt="" src="/Complere-white.svg" />
          </Link>
        </div>
        <div className={styles.wwwcomplerexyz}>
          The Compliant RWA Layer3 Chain
        </div>

        <div className={styles.mobele}>
          <div className={styles.mobItem}>
            <Link to="/about" style={{ textDecoration: "none", color: "#fff" }}>
              <div className={styles.mobsubItem}>About Us</div>
            </Link>
            <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
              <div className={styles.mobsubItem}>Features</div>
            </Link>
          </div>
          <div className={styles.mobItem}>
            <div className={styles.mobsubItem}>
              
              <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#FFFF" }}>
                Build
                </Link>
            </div>
            <Link to="/" style={{ textDecoration: "none", color: "#fff" }}>
              <div className={styles.mobsubItem}>Ecosystem</div>
            </Link>
          </div>
        </div>
        <div className={styles.moblogo}>
          <div>
            <a target="_blank" href="https://www.facebook.com/complere/">
              <img
                className={styles.discordIconmob}
                alt=""
                src="/facebook-img.svg"
              />
            </a>
          </div>
          <div>
            <a target="_blank" href="https://www.instagram.com/complerechain/">
              <img
                className={styles.discordIconmob}
                alt=""
                src="/instagram-img.svg"
              />
            </a>
          </div>

          <div>
            <a target="_blank" href="https://twitter.com/complerechain/">
              <img
                className={styles.discordIconmob}
                alt=""
                src="/icons8twitterx-1.svg"
              />
            </a>
          </div>
          <div>
            <a target="_blank" href="https://t.me/complerechain">
              <img
                className={styles.discordIconmob}
                alt=""
                src="/telegram-svgrepo-com.svg"
              />
            </a>
          </div>
          <div>
            <a
              target="_blank"
              href="https://www.linkedin.com/company/complere-chain/"
            >
              <img
                className={styles.discordIconmob}
                alt=""
                src="/linked-in1.svg"
              />
            </a>
          </div>
          <div>
            <a target="_blank" href="https://discord.gg/jjfmXJNhzS">
              <img
                className={styles.discordIconmob}
                alt=""
                src="/discord-svgrepo-com.svg"
              />
            </a>
          </div>
        </div>
        <div className={styles.moblowfoot}>
          © 2024 Complere Chain | All rights reserved
        </div>
        <hr className="solid" style={{ width: "90%", marginTop: "10px" }} />
        <Link to="/endofuser" style={{ textDecoration: "none", color: "#fff" }}>
          <div className={styles.mobbottom}>End User Licence Agreement </div>
        </Link>
        <div className={styles.mobfootbottom}>
          <Link to="/privacy" style={{ textDecoration: "none", color: "#fff" }}>
            <div className={styles.mobbottom}>Privacy Policy</div>
          </Link>
          <div
            onClick={() => (window.location = "mailto:hello@complere.xyz")}
            className={styles.mobbottom}
          >
            Contact Us
          </div>
        </div>
      </div>
    </div>
  );
};
export default Blog2;
