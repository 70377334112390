import FrameComponent4 from "./FrameComponent4";
import FrameComponent3 from "./FrameComponent3";
import styles from "./FrameComponent2.module.css";
import HorizontalScroll from "react-scroll-horizontal";
import Marquee from "react-fast-marquee";
import { useState, forwardRef } from "react";

const FrameComponent2 = () => {
  const [isHover, setIsHover] = useState(false);
  const [defiIsHover, setDefiIsHover] = useState(false);
  const [excHover, setExcHover] = useState(false);
  const [infraHover, setInfraHover] = useState(false);
  const [didHover, setDidHover] = useState(false);
  const [realHover, setRealHover] = useState(false);
  const [moneyHover, setMoneyHover] = useState(false);
  const [play, setPlay] = useState(true);
  const [pause, setPause] = useState(true);
  const handleMouseEnter = () => setIsHover(true);
  const handleMouseLeave = () => setIsHover(false);
  const handleMouseEnterDefi = () => setDefiIsHover(true);
  const handleMouseLeaveDefi = () => setDefiIsHover(false);
  const handleMouseEnterExc = () => setExcHover(true);
  const handleMouseLeaveExc = () => setExcHover(false);
  const handleMouseEnterInfra = () => setInfraHover(true);
  const handleMouseLeaveInfra = () => setInfraHover(false);
  const handleMouseEnterDid = () => setDidHover(true);
  const handleMouseLeaveDid = () => setDidHover(false);
  const handleMouseEnterReal = () => setRealHover(true);
  const handleMouseLeaveReal = () => {
    setRealHover(false);
    setPlay(true);
  };
  const handleMouseEnterMoney = () => setMoneyHover(true);
  const handleMouseLeaveMoney = () => setMoneyHover(false);

  //  console.log(isHover,'kop',infraHover,'ref')
  const cardsData = [
    {
      id: 1,
      title: "Tokenization",
      imgUrl: "/frameImg16.png",
    },
    {
      id: 2,
      title: "Commodity",
      imgUrl: "/frame-6@3x.png",
    },
    {
      id: 3,
      title: "DeFi",
    },
    {
      id: 4,
      title: "DePIN Infra",
    },
    {
      id: 5,
      title: "Money Market",
    },
    {
      id: 6,
      title: "Real Estate",
    },
    {
      id: 7,
      title: "DId",
    },
  ];

  return (
    <div id="hero" className={styles.desktop1Inner}>
      <div className={styles.groupParent}>
        <div className={styles.revolutionizingWeb3WithOurParent}>
          <div className={styles.revolutionizingWeb3With}>
            Enabling seamless interactions between Web3 and TradFi within a
            secure and legally compliant environment
          </div>
          <div className={styles.complereEcosystem}>
            <span className={styles.complereEcosystemTxtContainer}>
              <span className={styles.complere}>{`Complere `}</span>
              <i className={styles.ecosystem}>Ecosystem</i>
            </span>
          </div>
        </div>
        {/* <Marquee style={{width:'1588px !important',minwidth:'!important'}} className={styles.frameParent} play={play} pauseOnHover={true} >
       
          <a href='https://www.whr.loans/'  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} target="_blank" alt="Tokenization">
          {isHover?<FrameComponent4
            tokenization="Tokenization"
            propBackgroundImage="url('/frameImg16.png')"
            propLeft="140px"
          />:
          <FrameComponent4
            tokenization="Tokenization"
            copperCoinSvgrepoCom1="/coppercoinsvgrepocom-1@2x.png"
            propLeft="140px"
          />}
          </a> 
          <div onMouseEnter={handleMouseEnterDid} onMouseLeave={handleMouseLeaveDid}>
          {didHover?<FrameComponent4
            tokenization="DId"
            
            propLeft="1460px"
            propBackgroundImage="url('/comingImg10.png')"
            propLeft1="79.3px"
          />:<FrameComponent4
            tokenization="DId"
            copperCoinSvgrepoCom1="/fingerprintsvgrepocom-1.svg"
            propLeft="1460px"
            propBackgroundImage="url('/frame-8@3x.png')"
            propLeft1="79.3px"
          />}
          </div>
          
          <div onMouseEnter={handleMouseEnterReal} onMouseLeave={handleMouseLeaveReal}>
          {realHover?<FrameComponent4
            tokenization="Real Estate"
            propLeft="1240px"
            propBackgroundImage="url('/comingImg9.png')"
            propLeft1="49.3px"
          />:<FrameComponent4
            tokenization="Real Estate"
            copperCoinSvgrepoCom1="/building01svgrepocom-1.svg"
            propLeft="1240px"
            propBackgroundImage="url('/frame-7@3x.png')"
            propLeft1="49.3px"
          />}
          </div>
            <a href='https://anndhan.com/' onMouseEnter={handleMouseEnterExc} onMouseLeave={handleMouseLeaveExc} target="_blank" alt="Exchange">
          {excHover?<FrameComponent3
            pricePrediction="Commodity"
            model="Exchange"
            propLeft="360px"
            propBackgroundImage="url('/frameImg13.png')"
            propLeft1="45.4px"
          />:<FrameComponent3
            pricePrediction="Commodity"
            model="Exchange"
            aiMiAlgorithmSvgrepoCom1="/arrowsexchangevsvgrepocom-1.svg"
            propLeft="360px"
            propBackgroundImage="url('/frame-6@3x.png')"
            propLeft1="45.4px"
          />}
          </a>

          <a href='https://bru.finance/' onMouseEnter={handleMouseEnterDefi} onMouseLeave={handleMouseLeaveDefi} style={{cursor:"pointer"}} target="_blank" alt="Defi">
          {defiIsHover? <FrameComponent4
            tokenization="DeFi"
            propLeft="580px"
            propBackgroundImage="url('/bru-finance-bg.png')"
            propLeft1="75px"
          />:<FrameComponent4
            tokenization="DeFi"
            copperCoinSvgrepoCom1="/currencysvgrepocom-1.svg"
            propLeft="580px"
            propBackgroundImage="url('/frame-3@3x.png')"
            propLeft1="75px"
          />}
          </a>

          <div onMouseEnter={handleMouseEnterInfra} onMouseLeave={handleMouseLeaveInfra}>
          {infraHover?<FrameComponent4
            tokenization="DePIN Infra"
            propLeft="800px"
            propBackgroundImage="url('/comingImg12.png')"
            propLeft1="46.7px"
          />:<FrameComponent4
            tokenization="DePIN Infra"
            copperCoinSvgrepoCom1="/tokensvgrepocom-2.svg"
            propLeft="800px"
            propBackgroundImage="url('/frame-4@3x.png')"
            propLeft1="46.7px"
          />}
          </div>
        
          <div onMouseEnter={handleMouseEnterMoney} onMouseLeave={handleMouseLeaveMoney}>
          {moneyHover?<FrameComponent3
            pricePrediction="Money"
            model="Market"
            propLeft="1020px"
            propLeft1="62.4px"
            propBackgroundImage="url('/comingImg11.png')"
          />:<FrameComponent3
            pricePrediction="Money"
            model="Market"
            aiMiAlgorithmSvgrepoCom1="/money-market-logo.png"
            propLeft="1020px"
            propLeft1="62.4px"
            propBackgroundImage="url('/money-market-bg.png')"
          />}
          </div>
         
          </Marquee> */}
        <div className={styles.contain}>
          {/* <CardContainer cards={ cardsData } /> */}
          <div className={styles.cards_container}>
            {/* {cardsData.map((card) => (
              <Card title={ card.title }
                content={ card.content }
                imgUrl={ card.imgUrl } />
             
            ))} */}
            <a href='https://www.whr.loans/'  onMouseEnter={handleMouseEnter} onMouseLeave={handleMouseLeave} target="_blank" alt="Tokenization" style={{textDecoration:"none"}}>
            {isHover?<div className={styles.card}>
                <img src={"/frameImg16.png"} className={styles.card_img} alt={cardsData[0].alt || "Image"} />
                <div className={styles.card_content}>
                  <h2>{cardsData[0].title}</h2>
                  {/* <p>{cardsData[0].content}</p> */}
                </div>
              </div>:
              <div className={styles.card}>
              <img src={"/frame-1@3x.png"} className={styles.card_img} alt={cardsData[0].alt || "Image"} />
              <div className={styles.card_content}>
                <h2>{cardsData[0].title}</h2>
                <p>{cardsData[0].content}</p>
              </div>
            </div>}
            </a>
            <a href='https://anndhan.com/' onMouseEnter={handleMouseEnterExc} onMouseLeave={handleMouseLeaveExc} target="_blank" alt="Exchange" style={{textDecoration:"none"}}>
              {excHover?<div className={styles.card}>
                <img src={"/frameImg13.png"} alt={cardsData[1].alt || "Image"} />
                <div className={styles.card_content}>
                  <h2>{cardsData[1].title}</h2>
                  <p>{cardsData[1].content}</p>
                </div>
              </div>:<div className={styles.card}>
                <img src={"/frame-6@3x.png"} alt={cardsData[1].alt || "Image"} />
                <div className={styles.card_content}>
                  <h2>{cardsData[1].title}</h2>
                  <p>{cardsData[1].content}</p>
                </div>
              </div>}
              </a>
              <a href='https://bru.finance/' onMouseEnter={handleMouseEnterDefi} onMouseLeave={handleMouseLeaveDefi} style={{cursor:"pointer",textDecoration:"none"}} target="_blank" alt="Defi">
              {defiIsHover?<div className={styles.card}>
                <img src={"/bru-finance-bg.png"} alt={cardsData[2].alt || "Image"} />
                <div className={styles.card_content}>
                  <h2>{cardsData[2].title}</h2>
                  <p>{cardsData[2].content}</p>
                </div>
              </div>:<div className={styles.card}>
                <img src={"/frame-3@3x.png"} alt={cardsData[2].alt || "Image"} />
                <div className={styles.card_content}>
                  <h2>{cardsData[2].title}</h2>
                  <p>{cardsData[2].content}</p>
                </div>
              </div>}
              </a>
              <div onMouseEnter={handleMouseEnterInfra} onMouseLeave={handleMouseLeaveInfra}>
              {infraHover?<div className={styles.card}>
                <img src={"/comingImg12.png"} alt={cardsData[3].alt || "Image"} />
                <div className={styles.card_content}>
                  <h2>{cardsData[3].title}</h2>
                  <p>{cardsData[3].content}</p>
                </div>
              </div>:<div className={styles.card}>
                <img src={"/frame-4@3x.png"} alt={cardsData[3].alt || "Image"} />
                <div className={styles.card_content}>
                  <h2>{cardsData[3].title}</h2>
                  <p>{cardsData[3].content}</p>
                </div>
              </div>}
              </div>
              <div onMouseEnter={handleMouseEnterMoney} onMouseLeave={handleMouseLeaveMoney}>
              {moneyHover?<div className={styles.card}>
                <img src={"/comingImg11.png"} alt={cardsData[4].alt || "Image"} />
                <div className={styles.card_content}>
                  <h2>{cardsData[4].title}</h2>
                  <p>{cardsData[4].content}</p>
                </div>
              </div>:<div className={styles.card}>
                <img src={"/money-market-bg.png"} alt={cardsData[4].alt || "Image"} />
                <div className={styles.card_content}>
                  <h2>{cardsData[4].title}</h2>
                  <p>{cardsData[4].content}</p>
                </div>
              </div>}
              </div>
              <div onMouseEnter={handleMouseEnterReal} onMouseLeave={handleMouseLeaveReal}>
              {realHover?<div className={styles.card}>
                <img src={"/comingImg9.png"} className={styles.card_img} alt={cardsData[5].alt || "Image"} />
                <div className={styles.card_content}>
                  <h2>{cardsData[5].title}</h2>
                  <p>{cardsData[5].content}</p>
                </div>
              </div>:<div className={styles.card}>
                <img src={"/frame-7@3x.png"} className={styles.card_img} alt={cardsData[5].alt || "Image"} />
                <div className={styles.card_content}>
                  <h2>{cardsData[5].title}</h2>
                  <p>{cardsData[5].content}</p>
                </div>
              </div>}
              </div>
              <div onMouseEnter={handleMouseEnterDid} onMouseLeave={handleMouseLeaveDid}>
              {didHover?<div className={styles.card}>
                <img src={"/comingImg10.png"} className={styles.card_img} alt={cardsData[6].alt || "Image"} />
                <div className={styles.card_content}>
                  <h2>{cardsData[6].title}</h2>
                  <p>{cardsData[6].content}</p>
                </div>
              </div>:<div className={styles.card}>
                <img src={"/frame-8@3x.png"} className={styles.card_img} alt={cardsData[6].alt || "Image"} />
                <div className={styles.card_content}>
                  <h2>{cardsData[6].title}</h2>
                  <p>{cardsData[6].content}</p>
                </div>
              </div>}
              </div>
            
          </div>
        </div>
      </div>
    </div>
  );
};

export default forwardRef(FrameComponent2);
