import { useMemo } from "react";
import styles from "./Instagram.module.css";

const Instagram = ({
  instagram,
  instagramWidth,
  instagramHeight,
  instagramPosition,
  instagramTop,
  instagramLeft,
  bgHeight,
  bgWidth,
  bgTop,
  bgRight,
  bgBottom,
  bgLeft,
  bgHeight1,
  bgWidth1,
  bgTop1,
  bgRight1,
  bgBottom1,
  bgLeft1,
  bgHeight2,
  bgWidth2,
  bgTop2,
  bgRight2,
  bgBottom2,
  bgLeft2,
  instagramIconHeight,
  instagramIconWidth,
  instagramIconTop,
  instagramIconRight,
  instagramIconBottom,
  instagramIconLeft,
}) => {
  const instagramStyle = useMemo(() => {
    return {
      width: instagramWidth,
      height: instagramHeight,
      position: instagramPosition,
      top: instagramTop,
      left: instagramLeft,
    };
  }, [
    instagramWidth,
    instagramHeight,
    instagramPosition,
    instagramTop,
    instagramLeft,
  ]);

  const bg1Style = useMemo(() => {
    return {
      height: bgHeight,
      width: bgWidth,
      top: bgTop,
      right: bgRight,
      bottom: bgBottom,
      left: bgLeft,
    };
  }, [bgHeight, bgWidth, bgTop, bgRight, bgBottom, bgLeft]);

  const bg2Style = useMemo(() => {
    return {
      height: bgHeight1,
      width: bgWidth1,
      top: bgTop1,
      right: bgRight1,
      bottom: bgBottom1,
      left: bgLeft1,
    };
  }, [bgHeight1, bgWidth1, bgTop1, bgRight1, bgBottom1, bgLeft1]);

  const bg3Style = useMemo(() => {
    return {
      height: bgHeight2,
      width: bgWidth2,
      top: bgTop2,
      right: bgRight2,
      bottom: bgBottom2,
      left: bgLeft2,
    };
  }, [bgHeight2, bgWidth2, bgTop2, bgRight2, bgBottom2, bgLeft2]);

  const instagramIconStyle = useMemo(() => {
    return {
      height: instagramIconHeight,
      width: instagramIconWidth,
      top: instagramIconTop,
      right: instagramIconRight,
      bottom: instagramIconBottom,
      left: instagramIconLeft,
    };
  }, [
    instagramIconHeight,
    instagramIconWidth,
    instagramIconTop,
    instagramIconRight,
    instagramIconBottom,
    instagramIconLeft,
  ]);

  return (
    <div className={styles.instagram} style={instagramStyle}>
      <div className={styles.bg} style={bg1Style} />
      {/* <div className={styles.bg1} style={bg2Style} />
      <div className={styles.bg2} style={bg3Style} /> */}
      <img
        className={styles.instagramIcon}
        alt=""
        src={instagram}
        style={instagramIconStyle}
      />
    </div>
  );
};

export default Instagram;
