import { useMemo } from "react";
import styles from "./Linkedin.module.css";

const Linkedin = ({
  linkedIn,
  linkedinWidth,
  linkedinHeight,
  linkedinPosition,
  linkedinTop,
  linkedinLeft,
  bgHeight,
  bgWidth,
  bgTop,
  bgRight,
  bgBottom,
  bgLeft,
  bgBorderRadius,
  linkedInIconHeight,
  linkedInIconWidth,
  linkedInIconBottom,
  linkedInIconLeft,
}) => {
  const linkedinStyle = useMemo(() => {
    return {
      width: linkedinWidth,
      height: linkedinHeight,
      position: linkedinPosition,
      top: linkedinTop,
      left: linkedinLeft,
    };
  }, [
    linkedinWidth,
    linkedinHeight,
    linkedinPosition,
    linkedinTop,
    linkedinLeft,
  ]);

  const bgStyle = useMemo(() => {
    return {
      height: bgHeight,
      width: bgWidth,
      top: bgTop,
      right: bgRight,
      bottom: bgBottom,
      left: bgLeft,
      borderRadius: bgBorderRadius,
    };
  }, [bgHeight, bgWidth, bgTop, bgRight, bgBottom, bgLeft, bgBorderRadius]);

  const linkedInIconStyle = useMemo(() => {
    return {
      height: linkedInIconHeight,
      width: linkedInIconWidth,
      bottom: linkedInIconBottom,
      left: linkedInIconLeft,
    };
  }, [
    linkedInIconHeight,
    linkedInIconWidth,
    linkedInIconBottom,
    linkedInIconLeft,
  ]);

  return (
    <div className={styles.linkedin} style={linkedinStyle}>
      <div className={styles.bg} style={bgStyle} />
      <img
        className={styles.linkedInIcon}
        alt=""
        src={linkedIn}
        style={linkedInIconStyle}
      />
    </div>
  );
};

export default Linkedin;
