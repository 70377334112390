// import styles from "./Desktop.module.css";
import styles from "./EndOfUser.module.css";
import "react-tippy/dist/tippy.css";
import { Tooltip } from "react-tippy";
import FrameComponent from "../components/FrameComponent";
import { useState } from "react";
import { Link } from "react-router-dom";
import { ToastContainer, toast } from "react-toastify";

const EndOfUser = () => {
  const [isOpen, setIsOpen] = useState(false);
  const [learnButton, setLearnButton] = useState(false);
  const [learnMob, setLearnMob] = useState(false);

  const learnDropdown = () => {
    setLearnButton(true);
  };
  const learnDropdown2 = () => {
    setLearnButton(false);
  };
  const toggleNavbar = () => {
    setIsOpen(!isOpen);
    if (learnMob) {
      setLearnMob(false);
    }
  };
  const notify = () => {
    toast.success("Coming soon!");
  };
  const mobLearnMenu = () => {
    setLearnMob(!learnMob);
    setIsOpen(false);
  };
  return (
    <div className={styles.desktop1}>
      <div className={styles.groupParent}>
        <div className={styles.rectangleParent}>
          <div className={styles.groupChild} />
          <div className={styles.groupWrapper}>
            {/* <div className={styles.groupParent}>
              <div className={styles.groupContainer}>
                <div className={styles.groupParent}>
                  <img
                    className={styles.groupIcon}
                    alt=""
                    src="/rectangle-img.png"
                  />
                  <img
                    className={styles.groupIcon1}
                    alt=""
                    src="/rectangle-img.png"
                  />
                </div>
                <div className={styles.c}>C</div>
              </div>
              <div className={styles.complere}>Complere</div>
            </div> */}
            <ToastContainer
              position="top-right"
              autoClose={5000}
              hideProgressBar={false}
              newestOnTop={false}
              closeOnClick
              rtl={false}
              pauseOnFocusLoss
              draggable
            />
            <Link to="/">
              <img alt="" src="/complere_logo.svg" />
            </Link>
          </div>
          <div onClick={toggleNavbar} className={styles.ham}>
            <img
              className={styles.hamburgerMenuSvgrepoCom1Icon}
              alt=""
              src="/hamburger-menu.png"
            />
          </div>
          {isOpen && (
            <div className={styles.hamMenu}>
              <div onClick={mobLearnMenu} className={styles.menItem}>
                Learn
                <hr className="solid" />
              </div>
              <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#102c57" }}>
                <div className={styles.menItem}>
                  Build
                  <hr className="solid" />
                </div>
              </Link>
              <div onClick={notify} className={styles.menItem}>
                Explore
                <hr className="solid" />
              </div>
              <div className={styles.menItem}>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "var(--color-white)",
                  }}
                >
                  Ecosystem
                </Link>
                <hr className="solid" />
              </div>
              <Link target="_blank" to='https://complere-bridge-test-deploy.vercel.app/?destinationChain=complere-chain&sourceChain=base-sepolia' style={{ textDecoration: "none",color:"#C71585" }}>
              <div className={styles.menItem}>
                Bridge
                {/* <hr className="solid" /> */}
              </div>
              </Link>
            </div>
          )}

          {learnMob && (
            <div className={styles.learnMenu}>
              <Link to="/about" onClick={mobLearnMenu}>
                {" "}
                <div style={{ marginTop: "-5px" }} className={styles.learnItem}>
                  About us
                  <hr style={{ marginTop: "20px" }} className="solid" />
                </div>
              </Link>
              <Link to="/blog" style={{ textDecoration: "none" }} >
              <div className={styles.learnItem}>Blog
              <hr style={{ marginTop: "12px" }} className="solid" />
              </div>
              </Link>
              <Link to="/faq" style={{ textDecoration: "none" }} >
                  <div style={{ marginTop: "2px" }} className={styles.learnItem2}>FAQs</div>
                  </Link>
            </div>
          )}
          <div className={styles.groupDiv}>
            <div className={styles.bridgeParent}>
              {/* <Tooltip
                style={{ marginLeft: 5 }}
                interactive
                trigger="click"
                touchHold={true}
                stickyDuration={200}
                html={
                  <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
                    <strong style={{ fontFamily: "Inter" }}>
                      Coming soon...
                    </strong>
                  </div>
                }
              > */}
              <Link target="_blank" to='https://complere-bridge-test-deploy.vercel.app/?destinationChain=complere-chain&sourceChain=base-sepolia' style={{ textDecoration: "none",color:"#C71585" }}>

                <div className={styles.bridge}>Bridge</div>
                <img
                  className={styles.arrowUpRightFromSquareSvgIcon}
                  alt=""
                  src="/arrowuprightfromsquaresvgrepocom-1.svg"
                />
                </Link>
              {/* </Tooltip> */}
            </div>

            <div
              onMouseEnter={learnDropdown}
              onMouseLeave={learnDropdown2}
              className={styles.dropdownArrowSvgrepoCom1Parent}
            >
              {/* <Tooltip
                style={{ marginLeft: -20 }}
                interactive
                trigger="click"
                touchHold={true}
                stickyDuration={200}
                html={
                  <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
                    <strong style={{ fontFamily: "Inter" }}>
                      Coming soon...
                    </strong>
                  </div>
                }
              > */}
              <img
                className={styles.dropdownArrowSvgrepoCom1Icon}
                alt=""
                src="/dropdownarrowsvgrepocom-1.svg"
              />
              <div className={styles.bridge}>Learn</div>
              {learnButton && (
                <div className={styles.learnMenu}>
                  <Link to="/about" style={{ textDecoration: "none" }}>
                    <div className={styles.learnItem}>
                      About us
                      <hr className="solid" />
                    </div>
                  </Link>
                  <Link to="/blog" style={{ textDecoration: "none" }} >
                  <div className={styles.learnItem2}>Blog
                  <hr className="solid" /></div>
                  </Link>
                  <Link to="/faq" style={{ textDecoration: "none" }} >
              <div className={styles.learnItem2}>FAQs</div>
              </Link>
                </div>
              )}
              {/* </Tooltip> */}
            </div>

            <div className={styles.dropdownArrowSvgrepoCom2Parent}>
              {/* <Tooltip
                style={{ marginLeft: -10 }}
                interactive
                trigger="click"
                touchHold={true}
                stickyDuration={200}
                html={
                  <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
                    <strong style={{ fontFamily: "Inter" }}>
                      Coming soon...
                    </strong>
                  </div>
                }
              > */}
              <Link target="_blank" to='https://complere.gitbook.io/complere-chain-core-docs' style={{ textDecoration: "none",color:"#102c57" }}>

                <img
                  className={styles.dropdownArrowSvgrepoCom2Icon}
                  alt=""
                  src="/dropdownarrowsvgrepocom-1.svg"
                />
                <div className={styles.bridge}>Build</div>
                </Link>
              {/* </Tooltip> */}
            </div>
            <Tooltip
              style={{ marginLeft: 100 }}
              interactive
              trigger="click"
              touchHold={true}
              stickyDuration={200}
              html={
                <div style={{ padding: 10, width: 120, paddingBottom: 10 }}>
                  <strong style={{ fontFamily: "Inter" }}>
                    Coming soon...
                  </strong>
                </div>
              }
            >
              <div className={styles.dropdownArrowSvgrepoCom3Parent}>
                <img
                  className={styles.dropdownArrowSvgrepoCom3Icon}
                  alt=""
                  src="/dropdownarrowsvgrepocom-1.svg"
                />
                <div className={styles.bridge}>Explore</div>
              </div>
            </Tooltip>
            <div className={styles.dropdownArrowSvgrepoCom3Parent4}>
              <div className={styles.bridge}>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "var(--color-darkslateblue)",
                  }}
                >
                  Ecosystem
                </Link>
              </div>
            </div>
          </div>
          {/* <img className={styles.groupItem} alt="" src="/black-line.svg" />
      <img className={styles.groupInner} alt="" src="/vector-2.svg" />
      <img className={styles.vectorIcon} alt="" src="/vector-3.svg" />
      <img className={styles.groupChild1} alt="" src="/vector-4.svg" /> */}
        </div>
      </div>
      <div className={styles.policyText}>
        <p>
          PLEASE READ THIS TERMS & CONDITIONS DOCUMENT CAREFULLY. BY VISITING
          COMPLERE WEBSITE AND/OR USING ITS WEB/MOBILE APPLE, YOU AGREE TO BE
          BOUND BY THE TERMS AND CONDITIONS MENTIONED IN THIS DOCUMENT. This
          TERMS & CONDITIONS DOCUMENT ("T&C DOC") is a legal agreement between
          you ("User") and Complere, a blockchain company for the website and/or
          web/mobile applications (platform) offered by Complere (Complere/We).
          By visiting, sharing, installing, copying, or otherwise using the app,
          the User agrees to be bound by the terms of this T&C DOC.
        </p>
      </div>

      <div className={styles.policyText2}>
        <h4 className={styles.bold}>1. Acceptance of Terms & Conditions:</h4>
        <p>
          You agree to be bound by these terms and conditions by accessing and
          using this website. You should not use this website if you do not
          agree to these terms and conditions.
        </p>
        <h4 className={styles.bold1}>2. Use of the Website:</h4>
        <p>
          The content of this website is for general information purposes only.
          You may only use this website for lawful purposes and in a manner that
          does not infringe the rights of, or restrict or inhibit the use and
          enjoyment of, this website by any third party.
        </p>
        <h4 className={styles.bold1}>3. Restrictions on Use:</h4>
        <p>
          Complere platform is not open to use by the residents or citizens of
          China, Iran, Russia, North Korea, or any other person, entity, or
          country on the OFAC sanction list. In addition, the platform is not
          open to use by such residents or citizens of the United States of
          America (USA) who don’t qualify as accredited investors as per the
          regulations applicable in the USA. Any person who knowingly or
          unknowingly uses the platform will be liable to Complere and its
          founders, associate entities, employees or affiliate entities for any
          damage, penalties, or losses suffered by Complere and such person
          agrees to indemnify Complere from any such losses. Further, such a
          user of the platform agrees to forfeit its legal remedies, if any,
          against Complere and its founders, associates, employees, or
          affiliates entities.
        </p>
        <h4 className={styles.bold1}>4. Intellectual Property Rights:</h4>
        <p>
          Complere or its licensors own all copyright and other intellectual
          property rights in all text, images, and other material on this
          website. The user may not use any of the content of this website for
          commercial purposes without Complere’s express written consent.
        </p>
        <h4 className={styles.bold1}>5. Limitation of Liability:</h4>
        <p>
          We will not be liable for any loss or damage arising from or in
          connection with your website use. We do not warrant that the website
          will be error-free or uninterrupted, that defects will be corrected,
          or that the website or the server that makes it available is free of
          viruses or other harmful components.
        </p>
        <h4 className={styles.bold1}>6. Links:</h4>
        <p>
          This website may contain links to other websites not operated by us.
          We are not responsible for the content or availability of linked
          sites. Including any linked site does not imply endorsement by us of
          the site, and we are not responsible for any loss or damage that may
          result from your use of them.
        </p>
        <h4 className={styles.bold1}>7. Privacy Policy:</h4>
        <p>
          We take your privacy seriously. Our privacy policy explains how we use
          your personal data. By using this website, you consent to us
          processing your personal data in accordance with our privacy policy.
        </p>
        <h4 className={styles.bold1}>8. Applicable Law:</h4>
        <p>
          These terms and conditions are governed by and construed in accordance
          with Singapore law. Any disputes arising from or in connection with
          these terms and conditions shall be subject to the exclusive
          jurisdiction of the courts of Singapore.
        </p>
        <h4 className={styles.bold1}>9. Amendments:</h4>
        <p>
          We reserve the right to amend these terms and conditions without
          notice. Your continued use of the website following any changes will
          mean that you accept such changes.
        </p>
        <p>
          By visiting/using the platform or by installing or using the
          platform/applications, User agrees to be bound by the terms and
          conditions. If the user does not agree to the terms and
          conditions, the user user should immediately cease to visit the
          website and delete any or all apps associated with the business of
          Complere.
        </p>
      </div>
      <div className={styles.footer}>
        <FrameComponent />
      </div>
    </div>
  );
};
export default EndOfUser;
