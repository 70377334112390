import styles from "./Container.module.css";

const Container = () => {
  return (
    <div className={styles.groupParent}>
      <div className={styles.groupContainer}>
        <div className={styles.groupGroup}>
          {/* <img className={styles.groupIcon} alt="" src="/rectangle-img.png" />
          <img className={styles.groupIcon1} alt="" src="/rectangle-img.png" /> */}
        <img className={styles.groupIcon1} src="/circle-logo-img.png"/>
        </div>
        {/* <div className={styles.c}>C</div> */}
      </div>
      <div className={styles.groupChild} />
      <img
        className={styles.tickCircleSvgrepoCom1Icon}
        alt=""
        src="/tickcirclesvgrepocom-1.svg"
      />
    </div>
  );
};

export default Container;
