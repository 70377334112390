import { useEffect } from "react";
import {
  Routes,
  Route,
  useNavigationType,
  useLocation,
} from "react-router-dom";
import Desktop from "./pages/Desktop";
import PrivacyPolicy from "./pages/PrivacyPolicy";
import EndOfUser from "./pages/EndOfUser";
import About from "./pages/About";
import Blog from "./pages/Blogs/Blog";
import BlogPage from "./pages/BlogPage";
import Blog1 from "./pages/Blogs/Blog1";
import Blog2 from "./pages/Blogs/Blog2";
import Faq from "./pages/Faq";

function App() {
  const action = useNavigationType();
  const location = useLocation();
  const pathname = location.pathname;

  useEffect(() => {
    if (action !== "POP") {
      window.scrollTo(0, 0);
    }
  }, [action, pathname]);

  useEffect(() => {
    let title = "";
    let metaDescription = "";

    switch (pathname) {
      case "/":
        title = "";
        metaDescription = "";
        break;
    }

    if (title) {
      document.title = title;
    }

    if (metaDescription) {
      const metaDescriptionTag = document.querySelector(
        'head > meta[name="description"]'
      );
      if (metaDescriptionTag) {
        metaDescriptionTag.content = metaDescription;
      }
    }
  }, [pathname]);

  return (
    <Routes>
      <Route path="/" element={<Desktop />} />
      <Route path="/privacy" element={<PrivacyPolicy />} />
      <Route path="/endofuser" element={<EndOfUser />} />
      <Route path="/about" element={<About />} />
      <Route path="/blogpage" element={<BlogPage />} />
      <Route path="/blog" element={<Blog />} />
      <Route path="/blog/introduction-to-complere-chain" element={<Blog1 />} />
      <Route path="/blog/navigating-the-future" element={<Blog2 />} />
      <Route path="/faq" element={<Faq />} />

    </Routes>
  );
}
export default App;
