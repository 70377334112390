import styles from "./ContainerWrapper.module.css";

const ContainerWrapper = () => {
  return (
    // <div className={styles.groupParent}>
    //   <div className={styles.groupContainer}>
    //     <img className={styles.groupChild} alt="" src="/group-49.svg" />
    //     <div className={styles.parent}>
    //       <div className={styles.div}>8</div>
    //       <div className={styles.institutionalPartnerships}>
    //         Institutional Partnerships
    //       </div>
    //     </div>
    //   </div>
    //   <div className={styles.groupDiv}>
    //     <div className={styles.tokenizedCommoditiesParent}>
    //       <div className={styles.tokenizedCommodities}>
    //         <span className={styles.tokenizedCommoditiesTxt}>
    //           <p className={styles.tokenized}>Tokenized</p>
    //           <p className={styles.tokenized}>Commodities</p>
    //         </span>
    //       </div>
    //       <div className={styles.mWrapper}>
    //         <div className={styles.m}>$700M</div>
    //       </div>
    //     </div>
    //     <img className={styles.groupChild} alt="" src="/group-53.svg" />
    //   </div>
    // </div>
    <>
    <div className={styles.desktopVersionInner}>
        <div className={styles.groupParent2}>
          <div className={styles.groupParent3}>
            <div className={styles.groupParent4}>
              <img className={styles.groupItem} alt="" src="/group-65.svg" />
              <div className={styles.m}>$20M</div>
            </div>
            <div className={styles.onChainLoans}>On-chain Loans</div>
          </div>{/*done*/}
          <div className={styles.groupParent5}>
            <img className={styles.groupInner} alt="" src="/group-49.svg" />
            <div className={styles.div}>08</div>
            <div className={styles.institutionalPartnerships}>
              Institutional Partnerships
            </div>
          </div>{/*done*/}
          <img className={styles.vectorIcon} alt="" src="/vector-7.svg" />
          <img className={styles.groupChild1} alt="" src="/vector-7.svg" />
          <img className={styles.groupChild2} alt="" src="/vector-7.svg" />
          <div className={styles.tokenizedAssetsParent}>
            <div className={styles.tokenizedAssets}>Tokenized Assets</div>
            <div className={styles.mParent}>
              <div className={styles.m}>$700M</div>
              <img className={styles.groupItem} alt="" src="/group-71.svg" />
            </div>
          </div>{/*done*/}
          <div className={styles.creditLineSecuredParent}>
            <div className={styles.creditLineSecured}>Credit Line Secured</div>
            <div className={styles.mGroup}>
              <div className={styles.m}>$150M</div>
              <img className={styles.groupItem} alt="" src="/group-72.svg" />
            </div>
          </div>{/*done*/}
        </div>
      </div></>
  );
};

export default ContainerWrapper;
